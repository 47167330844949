import info from '../../../assets/info.svg'
import { useMobileMediaQuery } from '../../../hooks/useMobileMediaQuery'
import CustomTooltip from '../CustomTooltip'

interface PropsType {
    tooltipText: string
}

const ChartTooltip = (props: PropsType) => {
    const isMobile = useMobileMediaQuery()
    return (
        <CustomTooltip
            text={props.tooltipText}
            placement={isMobile ? 'bottom' : 'right'}>
            <img src={info} style={{ marginLeft: '10px' }} />
        </CustomTooltip>
    )
}

export default ChartTooltip
