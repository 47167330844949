import { Button, Form, Modal } from 'antd'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setTopbarTitle } from '../../../../../app/Reducers/TopbarSlice'
import { ReactComponent as OkIcon } from '../../../../../assets/checkMarkIcon.svg'
import { ReactComponent as AlertIcon } from '../../../../../assets/danger.svg'
import { ReactComponent as SaveIcon } from '../../../../../assets/save.svg'
import BackButton from '../../../../../common/components/commonUI/BackButton'
import TitleWithSubtitle from '../../../../../common/components/commonUI/TitleWithSubtitle'
import { useMobileMediaQuery } from '../../../../../hooks/useMobileMediaQuery'
import { CustomerApiFactory, CustomerDetail, CustomerDetailCustomerListsInner } from '../../../../../service/api'
import { ContactFormComponent } from '../ContactForm/ContactFormComponent'
import styles from './NewContact.module.css'

export const NewContact = () => {
    const api = CustomerApiFactory()
    const isMobile = useMobileMediaQuery()

    const [t] = useTranslation('translations')
    const [form] = Form.useForm()

    const [isSavedModalOpen, setIsSavedModalOpen] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [selectedLists, setSelectedLists] = useState<CustomerDetailCustomerListsInner[]>([])
    const [isSaving, setIsSaving] = useState<boolean>(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleFinish = (values: any) => {
        setIsSaving(true)
        const newContact: CustomerDetail = values as CustomerDetail
        newContact.birthDate = newContact.birthDate ? newContact.birthDate.valueOf() : undefined
        newContact.customerLists = selectedLists
        api.createCustomer(newContact).then(response => {
            setIsSavedModalOpen(true)
        }).catch((error: AxiosError) => {
            setIsError(true)
        }).finally(() => setIsSaving(false))
    }

    useEffect(() => {
        dispatch(setTopbarTitle(t('newContact')))
    }, [])

    return (
        <>
            <div className={styles.container}>
                <div className={styles.titleRow}>
                    {isMobile ?
                        <div className={styles.title}>
                            {t('newContact')}
                        </div>
                        :
                        <TitleWithSubtitle title={t('newContact')} />
                    }
                    <Button
                        loading={isSaving}
                        onClick={() => form.submit()}
                        className={`yellowFillPositiveButton ${styles.button}`}
                        icon={<SaveIcon />}>
                        {t('saveContact')}
                    </Button>
                </div >
                <BackButton />
                <ContactFormComponent
                    onListChange={(lists) => setSelectedLists(lists)}
                    handleFinish={(values) => handleFinish(values)}
                    form={form} />
                <Modal
                    closable={false}
                    className={'modal'}
                    open={isSavedModalOpen}
                    footer={
                        <>
                            <Button className={'blackFillPositiveButton'} onClick={() => navigate(-1)}>
                                OK
                            </Button>
                        </>
                    }>
                    <OkIcon></OkIcon>
                    <p>{t('contactSaved')}</p>
                </Modal>
                <Modal
                    closable={false}
                    className={'modal'}
                    open={isError}
                    footer={
                        <>
                            <Button className={'blackFillPositiveButton'} onClick={() => setIsError(false)}>
                                OK
                            </Button>
                        </>
                    }>
                    <AlertIcon></AlertIcon>
                    <p>{t('somethingWentWrong')}</p>
                </Modal>
            </div>
        </>
    )
}