import { Rate, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CustomSkeleton from '../../../../../common/components/CustomSkeleton'
import { formatDate } from '../../../../../common/utils'
import { CustomerAction, CustomerApiFactory, CustomerDetail, CustomerDevice, CustomerFeedback, CustomerPurchase } from '../../../../../service/api'
import { getTranslationFromActionType } from './ContactDetailFooter'
import styles from './ContactDetailFooter.module.css'

const MAX_TABLE_ROWS = 3
interface ContactDetailTableProps {
    activeType: ContactTableType
    selectedCustomer: CustomerDetail
}

export enum ContactTableType {
    History = 1,
    Feedback = 2,
    Devices = 3,
    Tickets = 4
}
export const ContactDetailTable = (props: ContactDetailTableProps): ReactElement => {
    const [t] = useTranslation('translations')
    const api = CustomerApiFactory()
    const [columns, setColumns] = useState<ColumnsType<any>>()
    const [dataSource, setDataSource] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const setHistoryColumnType = () => {
        setIsLoading(true)
        api.getCustomerActions(props.selectedCustomer.pk, MAX_TABLE_ROWS).then(response => {
            setDataSource(response.data)
        }).finally(() => setIsLoading(false))
        const columns: ColumnsType<CustomerAction> = [
            {
                title: t('dateTime'),
                dataIndex: 'date',
                render: (value) => <>{formatDate(dayjs(value), 'DD MMM YYYY HH:mm')}</>
            },
            {
                title: t('action'),
                dataIndex: 'type',
                render: (value) => <div>{getTranslationFromActionType(value)}</div>
            },
            {
                title: t('description'),
                dataIndex: 'description',
                render: (value) => <span className={styles.htmlDescription}dangerouslySetInnerHTML={{ __html: value }} />,
                ellipsis: true
            }
        ]
        return columns
    }
    const setFeedbackColumnType = () => {
        setIsLoading(true)
        api.getCustomerFeebacks(props.selectedCustomer.pk, MAX_TABLE_ROWS).then(response => {
            setDataSource(response.data)
        }).finally(() => setIsLoading(false))
        const columns: ColumnsType<CustomerFeedback> = [
            {
                title: t('dateTime'),
                dataIndex: 'date',
                render: (value) => <>{formatDate(dayjs(value), 'DD MMM YYYY HH:mm')}</>
            },
            {
                title: t('rate'),
                dataIndex: 'rating',
                render: (value) => <Rate disabled value={value} />
            },
            {
                title: t('message'),
                dataIndex: 'message',
                ellipsis: true
            }
        ]
        return columns
    }
    const setDevicesColumnType = () => {
        setIsLoading(true)
        api.getCustomerDevices(props.selectedCustomer.pk, MAX_TABLE_ROWS).then(response => {
            setDataSource(response.data)
        }).finally(() => setIsLoading(false))
        const columns: ColumnsType<CustomerDevice> = [
            {
                title: t('name'),
                dataIndex: 'name',
            },
            {
                title: t('deviceType'),
                dataIndex: 'type',
                render: (value) => <div>{getTranslationFromActionType(value)}</div>
            },
            {
                title: t('dateTime'),
                dataIndex: 'date',
                render: (value) => <>{formatDate(dayjs(value), 'DD MMM YYYY HH:mm')}</>
            }
        ]
        return columns
    }
    const setTicketsColumnType = () => {
        setIsLoading(true)
        api.getCustomerPurcahses(props.selectedCustomer.pk, MAX_TABLE_ROWS).then(response => {
            setDataSource(response.data)
        }).finally(() => setIsLoading(false))
        const columns: ColumnsType<CustomerPurchase> = [
            {
                title: t('orderDateTime'),
                dataIndex: 'date',
                render: (value) => <>{formatDate(dayjs(value), 'DD MMM YYYY HH:mm')}</>
            },
            {
                title: t('show'),
                dataIndex: 'title',
            },
            {
                title: t('seat'),
                dataIndex: 'seat',
            },
            {
                title: t('fare'),
                dataIndex: 'fare',
            },
            {
                title: t('price'),
                dataIndex: 'price',
                render: (value) => <>&#8364;{value.toFixed(2)}</>
            }
        ]
        return columns
    }
    useEffect(() => {
        switch (props.activeType) {
        case ContactTableType.History:
            setColumns(setHistoryColumnType())
            break
        case ContactTableType.Feedback:
            setColumns(setFeedbackColumnType())
            break
        case ContactTableType.Devices:
            setColumns(setDevicesColumnType())
            break
        case ContactTableType.Tickets:
            setColumns(setTicketsColumnType())
            break
        }
    }, [props.activeType])

    return (
        <>
            {isLoading ?
                <CustomSkeleton height={200} />
                :
                <>
                    <Table
                        scroll={{ x: 400 }}
                        rowKey={record => record.pk}
                        dataSource={dataSource}
                        columns={columns}
                        className={`tableView ${styles.table}`}
                        showSorterTooltip={false}
                        pagination={false}
                    />
                </>
            }
        </>
    )
}