import { Button, DatePicker, Form, Modal, TimePicker } from 'antd'
import dayjs from 'dayjs'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CloseIcon } from '../../../../../../../assets/close.svg'
import { ReactComponent as AlertIcon } from '../../../../../../../assets/danger.svg'
import { CustomerAction, CustomerApiFactory } from '../../../../../../../service/api'
import styles from './ContactActionComponent.module.css'
import { ContactEditor } from './TextEditor/ContactEditor'

interface ContactActionComponentProps {
    contactPk: number
    selectedAction?: CustomerAction
    menu: { open: boolean, action: ContactAction }
    onClose: () => void
    onFinish: () => void
}

export enum ContactAction {
    CALL = 1,
    SMS = 2,
    EMAIL = 3,
    WHATSAPP = 4,
    NOTE = 5
}
const DESCRIPTION_CHAR_LIMIT = 2048

export const ContactActionComponent = (props: ContactActionComponentProps): ReactElement => {
    const [t] = useTranslation('translations')
    const api = CustomerApiFactory()
    const [open, setOpen] = useState<boolean>(props.menu.open)
    const [title, setTitle] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [date, setDate] = useState<dayjs.Dayjs>()
    const [time, setTime] = useState<dayjs.Dayjs>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>()

    const setTitleFromAction = (action: ContactAction) => {
        switch (props.menu.action) {
        case ContactAction.CALL:
            setTitle(props.selectedAction ? t('editCall') : t('logCall'))
            break
        case ContactAction.EMAIL:
            setTitle(props.selectedAction ? t('editEmail') : t('logEmail'))
            break
        case ContactAction.NOTE:
            setTitle(props.selectedAction ? t('editNote') : t('logNote'))
            break
        case ContactAction.SMS:
            setTitle(props.selectedAction ? t('editSms') : t('logSms'))
            break
        case ContactAction.WHATSAPP:
            setTitle(props.selectedAction ? t('editWhatsapp') : t('logWhatsapp'))
            break
        }

    }

    useEffect(() => {
        if (props.selectedAction === undefined) {
            setTitleFromAction(props.menu.action)
            setOpen(props.menu.open)
        }
    }, [props.menu])

    const handleLogActivity = () => {
        const datetime = date!.set('hour', time!.hour()).set('minute', time!.minute())
        setIsLoading(true)
        api.createAction(props.contactPk, { actionDate: datetime.valueOf(), type: props.menu.action, htmlDescription: description })
            .then(response => {
                resetData()
                props.onClose()
                props.onFinish()
            }).catch(error => {
                setIsError(true)
            }).finally(() => setIsLoading(false))
    }

    const handleUpdateAction = () => {
        const datetime = date!.set('hour', time!.hour()).set('minute', time!.minute())
        api.updateAction(props.contactPk, props.selectedAction!.id, { pk: props.selectedAction!.id, actionDate: datetime.valueOf(), type: props.menu.action, htmlDescription: description })
            .then(response => {
                resetData()
                props.onClose()
                props.onFinish()
            }).catch(error => {
                setIsError(true)
            }).finally(() => setIsLoading(false))
    }

    useEffect(() => {
        const currentAction = props.selectedAction
        if (currentAction) {
            const date = dayjs(currentAction.date)
            setDate(date)
            setTime(date)
            setDescription(currentAction.description)
            setTitleFromAction(currentAction.type as ContactAction)
            setOpen(props.menu.open)
        }
    }, [props.selectedAction, props.menu.open])

    const resetData = () => {
        setDescription('')
        setDate(undefined)
        setTime(undefined)
    }

    return (
        <>
            <Modal
                closable={false}
                className={'modal'}
                open={isError}
                footer={
                    <>
                        <Button className={'blackFillPositiveButton'} onClick={() => {
                            setErrorMessage(undefined)
                            setIsError(false)
                        }}>
                            OK
                        </Button>
                    </>
                }>
                <AlertIcon></AlertIcon>
                <p>{errorMessage ?? t('somethingWentWrong')}</p>
            </Modal>
            <Modal
                open={open}
                closeIcon={<CloseIcon />}
                className={styles.contactActionModal}
                onCancel={() => {
                    resetData()
                    props.onClose()
                }}
                footer={
                    <>
                        {props.selectedAction ?
                            <div className={styles.buttonContainer}>
                                <Button
                                    loading={isLoading}
                                    disabled={!date || !time || description.trim().length === 0}
                                    onClick={() => {
                                        resetData()
                                        props.onClose()
                                    }}
                                    className={`blackFillPositiveButton ${styles.cancelButton}`}>
                                    {t('cancel')}
                                </Button>
                                <Button
                                    loading={isLoading}
                                    onClick={() => {
                                        if (description.length > DESCRIPTION_CHAR_LIMIT) {
                                            setErrorMessage(t('descriptionTooLong'))
                                            setIsError(true)
                                        } else {
                                            handleUpdateAction()
                                        }
                                    }}
                                    className={`yellowFillPositiveButton ${styles.saveButton}`}>
                                    {t('save')}
                                </Button>
                            </div>
                            :
                            <Button
                                loading={isLoading}
                                disabled={!date || !time || description.length === 0}
                                onClick={() => {
                                    if (description.length > DESCRIPTION_CHAR_LIMIT) {
                                        setErrorMessage(t('descriptionTooLong'))
                                        setIsError(true)
                                    } else {
                                        handleLogActivity()
                                    }
                                }}
                                className={`yellowFillPositiveButton ${styles.actionButton}`}>
                                {t('logActivity')}
                            </Button>
                        }
                    </>
                }>
                <h2>{title}</h2>
                <Form
                    className={styles.contactActionForm}
                    layout='vertical'
                >
                    <div className={styles.header}>
                        <Form.Item
                            label={t('date')}
                        >
                            <DatePicker
                                popupClassName={styles.popupStyle}
                                value={date}
                                onChange={date => setDate(date)}
                                placeholder={''}
                                className={styles.datePicker} />
                        </Form.Item>
                        <Form.Item
                            label={t('time')}
                        >
                            <TimePicker
                                popupClassName={styles.popupStyle}
                                needConfirm={false}
                                value={time}
                                onChange={time => setTime(time)}
                                format={'HH:mm'}
                                placeholder={''}
                                className={styles.timePicker} />
                        </Form.Item>
                    </div>
                </Form >
                <div >
                    <ContactEditor
                        toolbarClassName={'detailEditor'}
                        description={description}
                        onChange={(value) => setDescription(value)} />
                </div>
            </Modal >
        </>
    )
}