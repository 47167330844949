import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import noDataIconSvg from '../../assets/calendar-strikethrough.svg'
import styles from './NoData.module.css'
const WIDTH_THRESHOLD = 500

interface NoDataProps {
    style?: React.CSSProperties
    className?: string
}

const NoData = (props: NoDataProps) => {
    const [t] = useTranslation('translations')
    const ref = useRef<HTMLInputElement>(null)
    const [textVisible, setTextVisible] = useState<boolean>(false)

    useEffect(() => {
        const width = ref.current ? ref.current.offsetWidth : 0
        setTextVisible(width > WIDTH_THRESHOLD)
    }, [])
    return (
        <div style={{ ...props.style }} className={`${props.className} ${styles.noData}`} ref={ref}>
            <div className={styles.noDataContainer}>
                <img src={noDataIconSvg} className={styles.noDataIcon} />
                {textVisible && <p className={styles.noDataLabel}>{t('noDataInRange')}</p>}
            </div>
        </div>
    )
}

export default NoData