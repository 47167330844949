import { Avatar, Button, Checkbox, Divider, Modal, Select } from 'antd'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as AddIcon } from '../../../../../assets/add.svg'
import { ReactComponent as AlertIcon } from '../../../../../assets/alert.svg'
import { ReactComponent as ListIcon } from '../../../../../assets/listIcon.svg'
import { ReactComponent as OptionIcon } from '../../../../../assets/options.svg'
import { CustomerDetail, CustomerDetailCustomerListsInner, CustomerList } from '../../../../../service/api'
import styles from './ListComponent.module.css'
interface ListComponentProps {
    lists: CustomerList[]
    disabled: boolean
    selectedContact?: CustomerDetail
    onChange: (selectedLists: CustomerDetailCustomerListsInner[]) => void
}
export const ListComponent = (props: ListComponentProps): ReactElement => {
    const [t] = useTranslation('translations')
    const [showButton, setShowButton] = useState<boolean>(false)
    const [selectedLists, setSelectedLists] = useState<CustomerDetailCustomerListsInner[]>([])
    const [navigateModalOpen, setNavigateModalOpen] = useState<boolean>(false)
    const navigate = useNavigate()

    useEffect(() => {
        if (props.selectedContact && props.selectedContact.customerLists && props.selectedContact.customerLists.length > 0) {
            setSelectedLists(props.selectedContact.customerLists)
            setShowButton(false)
        } else {
            setShowButton(true)
        }
    }, [props.selectedContact])

    useEffect(() => {
        props.onChange(selectedLists)
    }, [selectedLists])

    useEffect(() => {
        setSelectedLists(props.selectedContact?.customerLists ?? [])
    }, [props.disabled])
    return (
        <>
            <div>
                <div className={styles.listContainer}>
                    <div className={styles.titleContainer}>
                        <Avatar className={styles.avatar} icon={<ListIcon />} />
                        <h1 className={styles.title}>{t('lists')}</h1>
                    </div>
                    <Divider />
                    {showButton ?
                        <Button
                            disabled={props.disabled}
                            icon={<AddIcon />}
                            onClick={() => {
                                setShowButton(false)
                            }}
                            className={`yellowFillPositiveButton ${styles.addNewListButton}`}>
                            {t('addToList')}
                        </Button>
                        :
                        <div className={styles.selectorContainer}>
                            <Select
                                onChange={(values: number[]) => {
                                    setSelectedLists(values.map(pk => {
                                        return props.lists.find(list => list.pk === pk)!
                                    }))
                                }}
                                disabled={props.disabled}
                                className={`${styles.itemSelector} ${props.disabled && styles.itemSelectorDisabled}`}
                                mode='multiple'
                                maxTagCount={'responsive'}
                                value={selectedLists.map(list => list.pk)}
                                options={[
                                    {
                                        label: t('customLists'),
                                        title: t('customLists'),
                                        options: props.lists.map(list => ({
                                            value: list.pk,
                                            label: list.name
                                        }))
                                    }
                                ]}
                            />
                            {(!showButton) &&
                                <Button
                                    onClick={() => {
                                        setNavigateModalOpen(true)
                                    }}
                                    disabled={props.disabled}
                                    className={styles.optionButton}
                                    icon={<OptionIcon />}
                                    type='text'
                                >
                                    {t('manageLists')}
                                </Button>}
                        </div>
                    }
                </div>
                <div className={styles.checkboxContainer}>
                    <Checkbox
                        disabled
                        className={styles.contactCheckbox}>
                        {t('userAdvertising')}
                    </Checkbox>
                    <Checkbox
                        disabled
                        checked={true}
                        className={styles.contactCheckbox}>
                        {t('userProfiling')}
                    </Checkbox>
                </div>
                <Modal
                    closable={false}
                    className={'modal'}
                    open={navigateModalOpen}
                    footer={
                        <>
                            <Button className='yellowOutlinedCancelButton' onClick={() => setNavigateModalOpen(false)}>
                                {t('keepEditing')}
                            </Button>
                            <Button className={'yellowFillPositiveButton'} onClick={() => navigate('../lists', { replace: true })}>
                                {t('yesImSure')}
                            </Button>
                        </>
                    }>
                    <AlertIcon />
                    <p>
                        {t('navigateQuestion')}
                    </p>
                </Modal>
            </div>
        </>
    )
}