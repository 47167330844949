import { Button } from 'antd'
import Table, { ColumnsType, TableProps } from 'antd/lib/table'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import closeDetail from '../../../../assets/close.svg'
import '../../../../common/commonCss/tableView.css'
import ChartTooltip from '../../../../common/components/charts/ChartTooltip'
import ExportCsv from '../../../../common/components/charts/ExportCsv'
import { useMobileMediaQuery } from '../../../../hooks/useMobileMediaQuery'
import { DashboardApiFactory } from '../../../../service/api'
import { formatDataByType } from '../table/TableList'
import styles from './ListChartDetail.module.css'
interface PropsType {
    title: string
    items: any[]
    tooltipText?: string
    columnLabels: string[]
    columnTypes: string[]
    dataSource: string
    onClose: () => void
}

const ListChartDetail = (props: PropsType) => {
    const api = DashboardApiFactory()
    const isMobile = useMobileMediaQuery()
    const [t, i18n] = useTranslation('translations')
    const [filteredAndSortedItems, setFilteredAndSortedItems] = useState<any[]>(props.items)
    const [columnLabels, setColumnLabels] = useState<string[]>([])
    const [columnTypes, setColumnTypes] = useState<string[]>([])
    const [data, setData] = useState<any[]>([])
    const dashboardState = useSelector((state: RootState) => state.dashboardState)
    const refresh = () => {
        const start = dashboardState.startDate.clone()
        const end = dashboardState.endDate.clone()
        api.getChartData(props.dataSource, {
            pks: dashboardState.selectedItemsIds,
            startDate: start.valueOf(),
            endDate: end.valueOf()
        }).then(response => {
            const rawData = response.data
            const labels: string[] = []
            rawData.labels.forEach((label) => {
                labels.push(i18n.t('translations:' + label))
            })
            setColumnLabels(labels)
            setColumnTypes(rawData.types)
            setData(rawData.data.map((item) => {
                const result = {}
                item.map((value, index) => ({
                    [labels[index]]: formatDataByType(rawData.types[index], value)
                })).forEach(object => {
                    if (object[i18n.t('translations:status')] !== undefined) {
                        object[i18n.t('translations:status')] = i18n.t('translations:' + object[i18n.t('translations:status')])
                    }
                    Object.assign(result, object)
                })
                return result
            }))
        })
    }
    useEffect(() => {
        refresh()
    }, [dashboardState.selectedItemsIds, dashboardState.startDate, dashboardState.endDate])
    useEffect(() => {
        setFilteredAndSortedItems(data)
    }, [data])

    const columns: ColumnsType<any> = columnLabels.map((column, index) => {
        return {
            title: column,
            dataIndex: column,
            key: column,
            fixed: (isMobile && index === 0) ? 'left' : undefined,
            ellipsis: true,
            sorter: (a, b) => {
                switch (columnTypes[index]) {
                case 'number': return a[column] - b[column]
                case 'string': return String(a[column]).localeCompare(String(b[column]))
                case 'date': return dayjs(a[column], 'DD MMM HH:mm').diff(dayjs(b[column], 'DD MMM HH:mm'))
                case 'day': return dayjs(a[column]).diff(dayjs(b[column]))
                default: return String(a[column]).localeCompare(String(b[column]))
                }
            },
        }
    })
    useEffect(() => {
        setData(props.items)
        setColumnLabels(props.columnLabels)
        setColumnTypes(props.columnTypes)
    }, [])
    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        setFilteredAndSortedItems(extra.currentDataSource)
    }
    return (
        <div className={styles.graphContainer}>
            <div className={styles.container}>
                <div className={styles.titleContainer}>
                    <div style={{ display: 'flex' }}>
                        <p className={styles.title}>{props.title}</p>
                        {props.tooltipText && <ChartTooltip tooltipText={props.tooltipText} />}
                    </div>

                    <div className={styles.actionButtonContainer}>
                        <ExportCsv filename={props.title} data={data} isImage={true} />
                        <Button className={styles.closeButton}
                            icon={<img src={closeDetail} />}
                            onClick={props.onClose} />
                    </div>

                </div>
                <div className={styles.tableContainer}>
                    <Table
                        scroll={{ x: 400 }}
                        onChange={onChange}
                        dataSource={filteredAndSortedItems}
                        columns={columns}
                        className={'tableView'}
                        showSorterTooltip={false}
                        pagination={{
                            locale: {
                                items_per_page: i18n.t('translations:itemsPerPage'),
                            },
                            position: ['bottomCenter'],
                            hideOnSinglePage: data.length <= 10,
                            showSizeChanger: true,
                            style: {
                                marginTop: '45px'
                            },
                            responsive: true,
                            showTitle: false
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default ListChartDetail