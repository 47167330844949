
import { Modal, notification } from 'antd'
import { Button } from 'antd/lib'
import { AxiosError } from 'axios'
import { ReactElement, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ReactComponent as ArrowIcon } from '../../../../assets/arrowDown.svg'
import { ReactComponent as OkIcon } from '../../../../assets/checkMarkIcon.svg'
import { ReactComponent as AlertIcon } from '../../../../assets/danger.svg'
import PopupMenu from '../../../../common/components/PopupMenu'
import { ExportMenuItem } from '../../../../common/components/commonUI/ExportMenuItem'
import { CustomerApiFactory, CustomerDetail } from '../../../../service/api'
import { OperationStatus } from '../../../users/AdminUtils'
import { createExportData } from './ContactUtils'
import styles from './ContactsBulkActions.module.css'

interface ContactBulkActionsProps {
    data: CustomerDetail[]
    onDelete: () => void
}

export const ContactsBulkActions = (props: ContactBulkActionsProps): ReactElement => {
    const customerApi = CustomerApiFactory()
    const [t] = useTranslation('translations')
    const [popupVisible, setPopupVisible] = useState<boolean>(false)
    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
    const [deletionResult, setDeletionResult] = useState<OperationStatus>({ operationErrors: [], operationSuccessful: [] })
    const [isError, setIsError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>()
    const [operationComplete, setOperationComplete] = useState<boolean>(false)

    const reset = () => {
        setDeletionResult({ operationErrors: [], operationSuccessful: [] })
    }
    const action = (action: string) => {
        notification.info({
            message: action
        })
    }

    const handleDelete = () => {
        reset()
        setIsDeleting(true)
        props.data.forEach(customer => {
            customerApi.deleteCustomer(customer.pk).then(response => {
                setDeletionResult(prevState => (
                    {
                        operationErrors: prevState.operationErrors, operationSuccessful: [...prevState.operationSuccessful, customer.pk]
                    })
                )
            }).catch((error: AxiosError) => {
                setDeletionResult(prevState => (
                    {
                        operationErrors: [...prevState.operationErrors, { pk: customer.pk, error: error.name }], operationSuccessful: prevState.operationSuccessful
                    }
                ))
            })
        })
    }
    const content = [
        /*{
            key: 1,
            label: <div>{t('addToList')}</div>,
            onClick: () => {
                setPopupVisible(false)
                action(t('addToList'))
            },
        },
        {
            key: 2,
            label: <div>{t('createList')}</div>,
            onClick: () => {
                setPopupVisible(false)
                action(t('createList'))
            }
        },*/
        {
            key: 3,
            label: <ExportMenuItem
                dataSource={() => createExportData(props.data)}
                disabled={props.data.length === 0}
                filename={t('contacts')}>
                <span className='ant-menu-title-content'>{t('exportSelected')}</span>
            </ExportMenuItem>,
            disabled: props.data.length === 0,
            onClick: () => {
                setPopupVisible(false)
            }
        },
        {
            key: 4,
            label: <div>{t('delete')}</div>,
            disabled: props.data.length === 0,
            onClick: () => {
                setPopupVisible(false)
                setIsDeleteModalOpen(true)
            }
        }
    ]

    useEffect(() => {
        if (props.data.length > 0 && (deletionResult.operationSuccessful.length === props.data.length)) {
            setIsDeleting(false)
            setIsDeleteModalOpen(false)
            setOperationComplete(true)
        } else if (deletionResult.operationErrors.length > 0) {
            setIsDeleting(false)
            setIsDeleteModalOpen(false)
            setIsError(true)
            setErrorMessage(t('somethingWentWrong'))
        }
    }, [deletionResult])

    return (
        <>
            <Modal
                closable={false}
                className={'modal'}
                open={isDeleteModalOpen}
                footer={
                    <>
                        <Button disabled={isDeleting} className='yellowOutlinedCancelButton' onClick={() => setIsDeleteModalOpen(false)}>
                            {t('cancel')}
                        </Button>
                        <Button loading={isDeleting} className={'blackFillPositiveButton'} onClick={handleDelete}>
                            {t('delete')}
                        </Button>
                    </>
                }>
                <AlertIcon />
                <Trans>{t('deleteContactQuestion', { count: props.data.length })}</Trans>
            </Modal>
            <Modal
                closable={false}
                className={'modal'}
                open={operationComplete}
                footer={
                    <>
                        <Button className={'blackFillPositiveButton'} onClick={() => {
                            props.onDelete()
                            setOperationComplete(false)
                        }}>
                            OK
                        </Button>
                    </>
                }>
                <OkIcon />
                <Trans>{t('contactDeleted', { count: deletionResult.operationSuccessful.length })}</Trans>
            </Modal >
            <Modal
                closable={false}
                className={'modal'}
                open={isError}
                footer={
                    <>
                        <Button className={'blackFillPositiveButton'} onClick={() => {
                            props.onDelete()
                            setIsError(false)
                        }}>
                            OK
                        </Button>
                    </>
                }>
                <AlertIcon />
                <p>{errorMessage}</p>
            </Modal>
            <PopupMenu popupVisible={popupVisible} content={content} >
                <Button
                    className={styles.button}
                    icon={<ArrowIcon />}
                    iconPosition='end'>{t('bulkActions')}</Button>
            </PopupMenu>
        </>
    )
}