import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { SegmentedValue } from 'antd/lib/segmented'
import dayjs from 'dayjs'
import i18n from '../../i18n'
import { DashboardConfiguration } from '../../pages/dashboard/newDashboard/DashboardModel'

interface DashboardState {
    startDate: dayjs.Dayjs
    endDate: dayjs.Dayjs
    selectedPresetValue: SegmentedValue
    selectedItemsIds: number[],
    config: DashboardConfiguration | undefined
}
const initialState: DashboardState = {
    startDate: dayjs().subtract(7, 'days').hour(0).minute(0).second(0),
    endDate: dayjs().subtract(1, 'days').hour(23).minute(59).second(59),
    selectedPresetValue: i18n.t('last7days'),
    config: undefined,
    selectedItemsIds: []
}
export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setStartDate: (state, action: PayloadAction<dayjs.Dayjs>) => {
            if (state.startDate !== action.payload) {
                state.startDate = action.payload
            }
        },
        setEndDate: (state, action: PayloadAction<dayjs.Dayjs>) => {
            if (state.endDate !== action.payload) {
                state.endDate = action.payload
            }
        },
        setSelectedPresetValue: (state, action: PayloadAction<string>) => {
            if (state.selectedPresetValue !== action.payload) {
                state.selectedPresetValue = action.payload
            }
        },
        setConfig: (state, action: PayloadAction<DashboardConfiguration | undefined>) => {
            if (action.payload) {
                state.config = action.payload
            }
        },
        setSelectedItemsIds: (state, action: PayloadAction<number[]>) => {
            if (state.selectedItemsIds !== action.payload) {
                state.selectedItemsIds = action.payload
            }
        },
        resetDashboardState: (state, action: PayloadAction<number[]>) => {
            state.startDate = initialState.startDate
            state.endDate = initialState.endDate
            state.selectedItemsIds = initialState.selectedItemsIds
            state.selectedPresetValue = initialState.selectedPresetValue
        }
    }
})

export const { setStartDate, setEndDate, setSelectedPresetValue, setConfig, setSelectedItemsIds } = dashboardSlice.actions
export default dashboardSlice.reducer