import { Button, Form, Input, Modal, notification } from 'antd'
import { AxiosError, AxiosResponse } from 'axios'
import _ from 'lodash'
import { ReactElement, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setSelectedAction, setSelectedVariant } from '../../../app/Reducers/InventorySlice'
import { RootState } from '../../../app/store'
import { ReactComponent as AlertIcon } from '../../../assets/danger.svg'
import '../../../common/commonCss/buttons.css'
import '../../../common/commonCss/modal.css'
import CustomSkeleton from '../../../common/components/CustomSkeleton'
import BackButton from '../../../common/components/commonUI/BackButton'
import TitleWithSubitile from '../../../common/components/commonUI/TitleWithSubtitle'
import { InventoryApiFactory, InventoryVariant, Location, Product } from '../../../service/api'
import styles from './InventoryDetail.module.css'
import { EditCard } from './InventoryDetailCard/EditCard'
import { VariantStocksComponent } from './InventoryDetailCard/VariantStocksComponent/VariantStocksComponent'
import { InventoryModalManager } from './StockModalManager/InventoryModalManager'

interface InventoryDetailParams {
    selectedProduct: Product
}

export interface AddWarehouseForm {
    name: string,
    address: string
}

export const InventoryDetail = (): ReactElement => {
    const [t] = useTranslation('translations')
    const location = useLocation()
    const selectedProduct: Product = (location.state as InventoryDetailParams).selectedProduct
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [variants, setVariants] = useState<InventoryVariant[]>([])
    const [selectedVariant, setSelctedVariant] = useState<InventoryVariant>()
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [defaultOpenVariant, setDefaultOpenVariant] = useState<number>(0)

    const [addWarehouseForm] = Form.useForm()
    const [addWarehouseOpen, setAddWarehouseOpen] = useState<boolean>(false)
    const [deleteWarehouseOpen, setDeleteWarehouseOpen] = useState<boolean>(false)
    const [deletionCompleteOpen, setDeletionCompleteOpen] = useState<boolean>(false)
    const [selectedLocation, setSelectedLocation] = useState<Location>()
    const inventoryState = useSelector((state: RootState) => state.inventoryState)
    const initialValue: AddWarehouseForm | undefined = undefined

    const dispatch = useDispatch()

    const api = InventoryApiFactory()
    const updateData = () => {
        setIsLoading(true)
        api.getInventoryVariants(selectedProduct.pk).then(response => {
            const variants = response.data
            setDefaultOpenVariant(variants[0].pk)
            setVariants(variants)
            setIsLoading(false)
        }).catch((error: AxiosError) => {
            notification.error({
                message: error.response?.status.toString(),
                description: error.message
            })
        })
    }
    useEffect(() => {
        updateData()
    }, [])

    const handleEditVariant = () => {
        console.log('edit')
    }

    const handleAddWarehouse = (newLocation: AddWarehouseForm) => {
        api.createLocation({ pk: null, name: newLocation.name, address: newLocation.address })
            .then((res: AxiosResponse) => {
                setAddWarehouseOpen(false)
                updateData()
                addWarehouseForm.resetFields()
            }).catch((error: AxiosError) => {
                console.log(error)
                notification.error({
                    message: error.response?.status.toString(),
                    description: error.message.concat(` (${error.response?.statusText})`)
                })
            })
    }

    const handleDelete = () => {
        api.deleteLocation(selectedLocation!.pk!).then(() => {
            setDeleteWarehouseOpen(false)
            updateData()
            setDeletionCompleteOpen(true)
        }).catch((error: AxiosError) =>
            notification.error({
                message: error.response?.status.toString(),
                description: error.message
            })
        )
    }

    useEffect(() => {
        (inventoryState.action !== undefined && inventoryState.warehouse) ? setIsModalOpen(true) : setIsModalOpen(false)
    }, [inventoryState])

    useEffect(() => {
        if (!selectedLocation) {
            updateData()
        }
    }, [inventoryState.operationComplete, selectedLocation])


    const getTotalStocks = (): number => {
        return _.sumBy(variants.map(variant =>
            _.sumBy(variant.inventoryLocations.filter(location => !location.virtual), datum => datum.count ? datum.count : 0)))
    }

    const handleChange = (open: boolean, variant: InventoryVariant) => {
        if (open) {
            dispatch(setSelectedVariant(variant))
            setSelctedVariant(variant)
        }
    }

    const handleWarehouseSelection = (location?: Location) => {
        setSelectedLocation(location)
    }

    const handleDeleteRequest = () => {
        setDeleteWarehouseOpen(true)
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.titleRowContainer}>
                    <TitleWithSubitile title={selectedProduct.title} />
                </div>
                <div>
                    <BackButton />
                </div>
                <div className={styles.contentContainer}>
                    <div className={styles.productDescriptionContainer}>
                        <div className={styles.detailDescription}>
                            <img src={selectedProduct.coverImage.imageUrl!} className={styles.productimage} />
                            <div className={styles.totalStocks}>{t('totalStocks')}{':'}</div>
                            <div className={styles.stockNumber}>{getTotalStocks()}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.variantsTitle}>
                    <div className={styles.variantsLabel}>{t('variants')}</div>
                    <div className={styles.variantsNumber}>{`(${variants.length})`}</div>
                </div>
                {variants.map(variant =>
                    <div key={variant.pk} className={styles.variant}>
                        {isLoading ?
                            <CustomSkeleton height={335} />
                            :
                            <div className={styles.variantContainer}>
                                <div className={styles.title} >
                                    {variant.name}
                                </div>
                                <EditCard
                                    onEdit={handleEditVariant}
                                    price={variant.price}
                                    sku={variant.sku} />
                                <VariantStocksComponent
                                    open={selectedVariant === variant}
                                    defaultOpen={defaultOpenVariant}
                                    onChange={handleChange}
                                    onSelection={handleWarehouseSelection}
                                    onAddNew={() => setAddWarehouseOpen(true)}
                                    onDelete={handleDeleteRequest}
                                    variant={variant} />
                            </div>
                        }
                    </div>
                )}
                {(isModalOpen && selectedVariant) &&
                    <InventoryModalManager
                        onCancel={() => {
                            dispatch(setSelectedAction(undefined))
                            setIsModalOpen(false)
                        }}
                        variant={selectedVariant!}
                        productTitle={selectedProduct.title}
                        open={isModalOpen} />}
                <Modal
                    closable={false}
                    className={`modal ${styles.modal}`}
                    maskClosable={false}
                    open={addWarehouseOpen}
                    footer={
                        <>
                            <Button
                                onClick={() => setAddWarehouseOpen(false)}
                                className={'yellowOutlinedCancelButton'}>
                                {t('cancel')}
                            </Button>
                            <Button
                                onClick={() => addWarehouseForm.submit()}
                                className={'yellowFillPositiveButton'}>
                                {t('addWarehouse')}
                            </Button>
                        </>
                    }
                >
                    <Form
                        initialValues={initialValue}
                        form={addWarehouseForm}
                        className={styles.form}
                        layout='vertical'
                        onFinish={handleAddWarehouse}
                        requiredMark={false}>
                        <Form.Item
                            label={t('newWarehouse')}
                            name={'name'}
                            rules={[
                                {
                                    required: true,
                                    message: ''
                                }
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={'address'}
                            label={t('address')}>
                            <Input />
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    closable={false}
                    className={`modal ${styles.modal}`}
                    maskClosable={false}
                    open={deleteWarehouseOpen}
                    footer={
                        <>
                            <Button
                                onClick={() => setDeleteWarehouseOpen(false)}
                                className={'yellowOutlinedCancelButton'}>
                                {t('cancel')}
                            </Button>
                            <Button
                                onClick={() => handleDelete()}
                                className={'blackFillPositiveButton'}>
                                {t('delete')}
                            </Button>
                        </>
                    }
                >
                    <AlertIcon />
                    <div className={styles.deleteModal}>
                        <Trans t={t} i18nKey='deleteWarehouseQuestion' >{{ warehouse: selectedLocation?.name }}</Trans>
                    </div>
                </Modal >
                <Modal
                    closable={false}
                    className={`modal ${styles.modal}`}
                    maskClosable={false}
                    open={deletionCompleteOpen}
                    footer={
                        <>
                            <Button
                                onClick={() => setDeletionCompleteOpen(false)}
                                className={'blackFillPositiveButton'}>
                                {'OK'}
                            </Button>
                        </>
                    }
                >
                    <AlertIcon />
                    <div className={styles.deleteModal}>
                        <Trans t={t} i18nKey='warehouseDeletionComplete' >{{ warehouse: selectedLocation?.name }}</Trans>
                    </div>
                </Modal >
            </div >
        </>
    )
}