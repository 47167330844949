import { Button, Col, Form, Input, Modal, Row, Select } from 'antd'
import { UploadFile } from 'antd/lib'
import 'dayjs/locale/it'
import { ReactElement, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ReactComponent as ArrowDown } from '../../../../../assets/arrowDown.svg'
import { ReactComponent as CloneIcon } from '../../../../../assets/clone.svg'
import { ReactComponent as AlertIcon } from '../../../../../assets/danger.svg'
import { ReactComponent as DeleteIcon } from '../../../../../assets/delete.svg'
import { Colors } from '../../../../../common/Colors'
import '../../../../../common/commonCss/modal.css'
import { CustomizeRequiredMark } from '../../../../../common/components/commonUI/CustomRequiredMark'
import { Image, OrganizationsApiFactory, Place, ProductVariant } from '../../../../../service/api'
import SingleImageUploader from '../ImageUploader/SingleImageUploader'
import { VariantPayload } from './CollpasibleList'
import styles from './variantForm.module.css'

const { Option } = Select

interface variantFormProps {
    variantsList: ProductVariant[]
    variant?: VariantPayload
    onChange: (variantPayload: VariantPayload) => void
    handleDelete: () => void
    handleClone: () => void
}

const VariantForm = (props: variantFormProps): ReactElement => {
    const organizationApi = OrganizationsApiFactory()
    const [t] = useTranslation('translations')
    const [isTrashModalOpen, setIsTrashModalOpen] = useState<boolean>(false)
    const [poiList, setPoiList] = useState<Place[]>([])
    const [variantsList, setVariantsList] = useState<ProductVariant[]>([])
    const [file, setFile] = useState<UploadFile | undefined>(props.variant?.file)
    const [variantForm] = Form.useForm()
    const [currentVariant, setCurrentVariant] = useState<ProductVariant>(props.variant?.variant ?? {
        name: '',
        sku: '',
        duration: null,
        image: { id: null, imageUrl: null },
        pk: null,
        price: null,
        locations: [],
        linkedVariant: { pk: -1 },
    })

    useEffect(() => {
        organizationApi.getAssociatedPoiList().then((response) => {
            setPoiList(response.data)
        })
    }, [])

    useEffect(() => {
        if (currentVariant !== undefined) {
            setVariantsList(props.variantsList.filter(v => currentVariant.pk !== v.pk))
        }
    }, [props.variantsList])

    useEffect(() => {
        if (customValidation()) {
            props.onChange({ variant: currentVariant, file: file, validation: true })
        } else {
            props.onChange({ variant: currentVariant, file: file, validation: false })
        }
    }, [currentVariant, file])

    const customValidation = (): boolean => {
        if (currentVariant.name &&
            (variantForm.getFieldValue('image') as Image).id !== null &&
            currentVariant.duration &&
            currentVariant.price &&
            currentVariant.sku &&
            currentVariant.locations.length > 0) {
            return true
        }
        return false
    }
    return (
        <Form
            initialValues={({
                name: currentVariant.name,
                sku: currentVariant.sku,
                price: currentVariant.price,
                duration: currentVariant.duration,
                variantLinked: currentVariant.linkedVariant ? currentVariant.linkedVariant.pk : null,
                visitableMuseums: currentVariant.locations.map(location => location.pk),
                image: currentVariant.image
            })}
            form={variantForm}
            requiredMark={CustomizeRequiredMark}
            layout='vertical'
            className={`form ${styles.variant}`}>
            <Row justify={'space-between'}>
                <Col span={8}>
                    <Form.Item
                        name={'name'}
                        rules={[
                            {
                                required: true,
                                message: ''
                            }
                        ]}
                        label={t('internalName')}>
                        <Input
                            onChange={event => setCurrentVariant(prevState => ({ ...prevState, name: event.target.value }))}
                            value={currentVariant.name} />
                    </Form.Item>
                </Col>
                <Col span={7}>
                    <Form.Item
                        name={'sku'}
                        rules={[
                            {
                                required: true,
                                message: ''
                            }
                        ]}
                        label={t('SKU')}>
                        <Input
                            onChange={event => setCurrentVariant(prevState => ({ ...prevState, sku: event.target.value }))}
                            value={currentVariant.sku} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name={'price'}
                        rules={[
                            {
                                required: true,
                                message: ''
                            }
                        ]}
                        label={t('price')}>
                        <Input
                            type='number'
                            onChange={event => setCurrentVariant(prevState => ({ ...prevState, price: parseInt(event.target.value) }))}
                            value={currentVariant.price ?? undefined} />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify={'space-between'} align={'middle'}>
                <Col span={11}>
                    <Form.Item
                        name={'duration'}
                        rules={[
                            {
                                required: true,
                                message: ''
                            }
                        ]}
                        label={t('duration')}>
                        <Input
                            type='number'
                            onChange={event => setCurrentVariant(prevState => ({ ...prevState, duration: parseInt(event.target.value) }))}
                            value={currentVariant.duration ?? undefined}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={'variantLinked'}
                        rules={[
                            {
                                required: false,
                            }
                        ]}
                        label={t('variantLinked')}>
                        <Select
                            onChange={value => {
                                setCurrentVariant(prevState => ({ ...prevState, linkedVariant: ({ pk: Number(value) }) }))
                            }
                            }
                            value={currentVariant.linkedVariant?.pk}
                            suffixIcon={<ArrowDown />}
                            className={styles.select}
                            options={variantsList.map(variant => {
                                return {
                                    key: variant.pk,
                                    value: variant.pk,
                                    label: <span>{variant.name}</span>
                                }
                            })}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                        name={'visitableMuseums'}
                        rules={[
                            {
                                required: true,
                                message: ''
                            }
                        ]}
                        label={t('visitableMuseums')}>
                        <Select
                            mode='multiple'
                            allowClear
                            suffixIcon={<ArrowDown />}
                            className={styles.largeSelector}
                            value={currentVariant.locations ? currentVariant.locations : null}
                            onChange={(value) => {
                                const poi = value.map(pk => {
                                    return poiList.find(poi => poi.pk === Number(pk))!
                                })
                                setCurrentVariant(prevState => ({ ...prevState, locations: poi }))
                            }}
                            options={poiList.map(point => {
                                return {
                                    key: point.pk,
                                    value: point.pk,
                                    label: <span>{point.name}</span>
                                }
                            })} />

                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={'image'}
                        rules={[
                            {
                                required: true,
                                message: ''
                            }
                        ]}
                        label={t('image')}>
                        <SingleImageUploader
                            onLoad={fileList => {
                                if (fileList.length === 0) {
                                    setFile(undefined)
                                    variantForm.setFieldValue('image', { id: null, imageUrl: null })
                                    setCurrentVariant(prevState => ({ ...prevState, image: { id: null, imageUrl: null } }))
                                } else {
                                    fileList.map(file => {
                                        if (file.originFileObj) {
                                            setFile(file)
                                            variantForm.setFieldValue('image', 'nome')
                                            currentVariant.image = { id: null, imageUrl: null }
                                        }
                                    })
                                }
                            }}
                            file={file}
                            productImage={currentVariant.image ?? undefined} />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify={'end'}>
                <Button
                    onClick={() => setIsTrashModalOpen(true)}
                    icon={<DeleteIcon color={Colors.yellow}></DeleteIcon>}
                    className={`redFillCancelButton ${styles.commonButton} ${styles.deleteButton}`}>
                    {t('delete')}
                </Button>
                <Button
                    onClick={props.handleClone}
                    icon={<CloneIcon color={Colors.yellow}></CloneIcon>}
                    className={`blackFillPositiveButton ${styles.commonButton}`}>
                    {t('clone')}
                </Button>
                <Modal
                    className={`modal ${styles.trashModal}`}
                    closable={false}
                    open={isTrashModalOpen}
                    okText={t('moveToTrash')}
                    onOk={() => {
                        props.handleDelete()
                        setIsTrashModalOpen(false)
                    }}
                    onCancel={() => setIsTrashModalOpen(false)}
                    cancelText={t('cancel')}>
                    <AlertIcon />
                    <p><Trans>{t('modalQuestionMessage', { cardTitle: currentVariant.name ?? '' })}</Trans></p>
                </Modal>
            </Row>
        </Form >
    )
}

export default VariantForm