import { ReactElement } from 'react'
import { useCSVDownloader } from 'react-papaparse'

export const ExportMenuItem = ({ dataSource, children, disabled, filename }: { dataSource: any, children: ReactElement, disabled?: boolean, filename: string }): ReactElement => {
    const { CSVDownloader } = useCSVDownloader()
    if (disabled) {
        return children
    }
    return (
        <CSVDownloader
            filename={filename}
            bom={true}
            data={dataSource}
        >
            {children}
        </CSVDownloader>
    )
}
