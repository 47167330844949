
import { Quill } from 'react-quill'
// Undo and redo functions for Custom Toolbar

const Size = Quill.import('formats/size')
Size.whitelist = ['extra-small', 'small', 'medium', 'large']
Quill.register(Size, true)

const Font = Quill.import('formats/font')
Font.whitelist = [
    'arial',
    'comic-sans',
    'courier-new',
    'georgia',
    'helvetica',
    'lucida'
]
Quill.register(Font, true)


// Formats objects for setting up the Quill editor
export const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'align',
    'strike',
    'script',
    'blockquote',
    'background',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
    'code-block'
]

// Quill Toolbar component
export const QuillToolbar = ({ id = 'toolbar' }) => (
    <div id={id}>
        {/*<span className='ql-formats'>
            <button className='ql-undo'>
                <CustomUndo />
            </button>
            <button className='ql-redo'>
                <CustomRedo />
            </button>
</span>*/}
        <span className='ql-formats'>
            <select className='ql-header' defaultValue='3'>
                <option value='1'>Heading</option>
                <option value='2'>Subheading</option>
                <option value='3'>Normal</option>
            </select>
        </span>
        <span className='ql-formats'>
            <select className='ql-align' />
        </span>
        <span className='ql-formats'>
            <select className='ql-color' />
        </span>
        <span className='ql-formats'>
            <button className='ql-bold' />
        </span>
        <span className='ql-formats'>
            <button className='ql-italic' />
        </span>
        <span className='ql-formats'>
            <button className='ql-underline' />
        </span>
        <span className='ql-formats'>
            <button className='ql-strike' />
        </span>
        <span className='ql-formats'>
            <button className='ql-code-block' />
        </span>
        <span className='ql-formats'>
            <button className='ql-list' value='ordered' />
            <button className='ql-list' value='bullet' />
        </span>
    </div>
)

export default QuillToolbar
