import { Avatar, Form, FormInstance, Input, Select } from 'antd'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as IconPlaceHolder } from '../../../../assets/OrganizationsDefaultIcon.svg'

import CustomSkeleton from '../../../../common/components/CustomSkeleton'
import { OrganizationDetail, OrganizationsApiFactory, Place } from '../../../../service/api'
import styles from './OrganizationsForm.module.css'

const { Option } = Select

interface OrganizationFormProps {
    form: FormInstance<any>
    disabled: boolean
    data?: OrganizationDetail
    onFinish: (values: OrganizationDetail) => void
}
interface OrganizationFormValues {
    pk?: number
    name?: string
    groupName?: string
    entranceRefundValue?: number
    salesRefundValue?: number
    salesRefundPercentual?: number
    poi?: number[]
    pos?: number[]
}
export const OrganizationsForm = ({ form, disabled, data, onFinish }: OrganizationFormProps): ReactElement => {
    const api = OrganizationsApiFactory()
    const [t] = useTranslation('translations')
    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const [poi, setPoi] = useState<Place[]>([])
    const [pos, setPos] = useState<Place[]>([])
    const [selectedPoiPk, setSelectedPoiPk] = useState<number[]>([])
    const [selectedPosPk, setSelectedPosPk] = useState<number[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    useEffect(() => {
        setSelectedPoiPk((data && data.poi) ? [data.poi.pk!] : [])
        setSelectedPosPk((data && data.pos) ? [data.pos.pk!] : [])
        form.setFieldsValue({
            pk: data?.pk,
            name: data?.name,
            groupName: data?.groupName,
            salesRefundValue: data?.metadata?.salesRefundValue,
            salesRefundPercentual: data?.metadata?.salesRefundPercentual,
            entranceRefundValue: data?.metadata?.entranceRefundValue,
        })
    }, [data])

    useEffect(() => {
        setIsLoading(true)
        Promise.all([
            api.getPoi().then(response => {
                setPoi(response.data)
            }),
            api.getPos().then(response => {
                setPos(response.data)
            })]
        ).then(() => {
            form.setFieldsValue
            setIsLoading(false)
        })
    }, [])

    useEffect(() => {
        setIsDisabled(disabled)
    }, [disabled])

    return (
        <>
            {isLoading ?
                <CustomSkeleton height={300} />
                :
                <>
                    <div className={styles.accountImage}>
                        <Avatar
                            icon={<IconPlaceHolder className={styles.organizationImage}></IconPlaceHolder>}
                            shape='circle'
                            size={140} />
                    </div>
                    <div className={styles.formContainer}>
                        <Form
                            form={form}
                            requiredMark={false}
                            onFinish={(values: OrganizationFormValues) => {
                                const currentData: OrganizationDetail = {
                                    pk: values.pk!,
                                    name: values.name!,
                                    groupName: values.groupName!,
                                    metadata: {
                                        salesRefundValue: Number(values.salesRefundValue!),
                                        salesRefundPercentual: Number(values.salesRefundPercentual!),
                                        entranceRefundValue: Number(values.entranceRefundValue!)
                                    },
                                    poi: (selectedPoiPk && selectedPoiPk.length > 0) ? { pk: selectedPoiPk[0] } : undefined,
                                    pos: (selectedPosPk && selectedPosPk.length > 0) ? { pk: selectedPosPk[0] } : undefined,
                                }
                                onFinish(currentData)
                            }}
                            layout='vertical'
                            className={`${isDisabled ? styles.formDisabled : styles.formEnabled} ${styles.form}`}>
                            <Form.Item
                                name={'name'}
                                label={t('organization')}
                                rules={[
                                    {
                                        required: true,
                                        message: ''
                                    }
                                ]}>
                                <Input disabled={isDisabled} />
                            </Form.Item>
                            <Form.Item
                                name={'entranceRefundValue'}
                                label={`${t('entranceRefundValue')} (€)`}
                                rules={[
                                    {
                                        required: true,
                                        message: ''
                                    }
                                ]}>
                                <Input type='number' disabled={isDisabled} />
                            </Form.Item>
                            <Form.Item
                                name={'salesRefundPercentual'}
                                label={`${t('salesRefundPercentual')} (%)`}
                                rules={[
                                    {
                                        required: true,
                                        message: ''
                                    }
                                ]}>
                                <Input type='number' disabled={isDisabled} />
                            </Form.Item>
                            <Form.Item
                                name={'groupName'}
                                label={`${t('group')} ${t('optional')}`}>
                                <Input disabled={isDisabled} />
                            </Form.Item>
                            <Form.Item
                                name={'salesRefundValue'}
                                label={`${t('salesRefundValue')} (€)`}
                                rules={[
                                    {
                                        required: true,
                                        message: ''
                                    }
                                ]}>
                                <Input type='number' disabled={isDisabled} />
                            </Form.Item>
                            <div className={`${styles.organizations} ${styles.form}`}>
                                <Form.Item
                                    className={styles.organizationsItem}
                                    label={`${t('pointOfInterest')} ${t('optional')}`}>
                                    <Select
                                        value={selectedPoiPk}
                                        mode='tags'
                                        onChange={(value, options: any[]) => {
                                            // update data only when select one item or clear action
                                            if (options?.length === 0 || options?.length === 1) {
                                                setSelectedPoiPk(value)
                                            } else if (options.length > 1) {
                                                value.shift()
                                                setSelectedPoiPk(value)
                                            }
                                        }}
                                        options={poi.map(point => {
                                            return {
                                                key: point.pk,
                                                value: point.pk,
                                                label: <span>{point.name}</span>
                                            }
                                        })}
                                        allowClear={true}
                                        disabled={isDisabled}
                                        className={`${styles.itemSelector} ${isDisabled ? styles.itemSelectorDisabled : styles.itemSelectorEnabled}`} />
                                </Form.Item>
                                <Form.Item
                                    className={styles.organizationsItem}
                                    label={`${t('pointOfSale')} ${t('optional')}`}>
                                    <Select
                                        value={selectedPosPk}
                                        allowClear={true}
                                        disabled={isDisabled}
                                        mode='tags'
                                        onChange={(value, options: any[]) => {
                                            // update data only when select one item or clear action
                                            if (options?.length === 0 || options?.length === 1) {
                                                setSelectedPosPk(value)
                                            } else if (options.length > 1) {
                                                value.shift()
                                                setSelectedPosPk(value)
                                            }
                                        }}
                                        className={`${styles.itemSelector} ${isDisabled ? styles.itemSelectorDisabled : styles.itemSelectorEnabled}`}
                                        options={pos.map(point => {
                                            return {
                                                key: point.pk,
                                                value: point.pk,
                                                label: <span>{point.name}</span>
                                            }
                                        })} />
                                </Form.Item>
                            </div>
                        </Form >
                    </div >
                </>
            }
        </>
    )
}
