import { ConfigProvider, notification } from 'antd'
import enGB from 'antd/locale/en_GB'
import itIT from 'antd/locale/it_IT'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { store } from './app/store'
import './fonts.css'
import './i18n'
import i18n from './i18n'
import './index.css'
const basename = document.querySelector('base')?.getAttribute('href') ?? '/'
const root = document.getElementById('root')

notification.config({
    placement: 'bottomRight',
    duration: undefined,
    maxCount: 5,
})

i18n.changeLanguage()
let antdLocale = enGB
const language = i18n.language
dayjs.locale(language)
dayjs.extend(LocalizedFormat)
switch (language) {
case 'it-IT': {
    antdLocale = itIT
    break
}
case 'en-GB': {
    antdLocale = enGB
    break
}
}
if (root !== null) {
    const reactRoot = ReactDOM.createRoot(root)
    reactRoot.render(
        <React.StrictMode>
            <BrowserRouter basename={basename}>
                <Provider store={store}>
                    <ConfigProvider locale={antdLocale} >
                        <App />
                    </ConfigProvider>
                </Provider>
            </BrowserRouter>
        </React.StrictMode>
    )
}
