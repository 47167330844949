import { Button, Modal } from 'antd'
import Search from 'antd/es/input/Search'
import { AxiosError } from 'axios'
import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as OrganizationIcon } from '../../../assets/Organization.svg'
import { ReactComponent as CheckMarkIcon } from '../../../assets/checkMarkIcon.svg'
import { ReactComponent as AlertIcon } from '../../../assets/danger.svg'
import searchStyles from '../../../common/commonCss/Search.module.css'
import '../../../common/commonCss/modal.css'
import TitleWithSubtitle from '../../../common/components/commonUI/TitleWithSubtitle'
import { Organization, OrganizationsApiFactory } from '../../../service/api'
import { OperationStatus } from '../AdminUtils'
import styles from './Organizations.module.css'
import OrganizationsTable from './OrganizationsTable'

const Organizations = (): ReactElement => {
    const api = OrganizationsApiFactory()
    const [t] = useTranslation('translations')
    const [organizations, setOrganizations] = useState<Organization[]>([])
    const [filteredOrganizations, setFilteredOrganizations] = useState<Organization[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [selectedOrganizations, setSelectedOrganizations] = useState<React.Key[]>([])
    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const [deletionResult, setDeletionResult] = useState<OperationStatus>({ operationErrors: [], operationSuccessful: [] })
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
    const [deleteResultOpen, setDeleteResultOpen] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const navigate = useNavigate()

    const updateData = () => {
        setIsLoading(true)
        setDeletionResult({ operationErrors: [], operationSuccessful: [] })
        setSelectedOrganizations([])
        api.getOrganizations().then(response => {
            const organizations = response.data
            setOrganizations(organizations)
            setIsLoading(false)
            setFilteredOrganizations(organizations)
        })
    }

    useEffect(() => {
        updateData()
    }, [])

    const handleCreateOrganization = () => {
        navigate('new')
    }

    const handleRowSelect = (selectedRows: React.Key[]) => {
        setSelectedOrganizations(selectedRows)
    }

    const handleDelete = () => {
        setIsDeleting(true)
        selectedOrganizations.forEach(organization => {
            const organizationPk = Number(organization.valueOf())
            api.deleteOrganization(organizationPk).then((res) => {
                setDeletionResult(prevState => ({
                    operationErrors: [...prevState.operationErrors],
                    operationSuccessful: [...prevState.operationSuccessful, organizationPk]
                }))
            }).catch((error: AxiosError) => {
                setDeletionResult(prevState => ({
                    operationErrors: [...prevState.operationErrors, { pk: organizationPk, error: error.name }],
                    operationSuccessful: [...prevState.operationSuccessful]
                }))
            }).finally(() => {
                setIsDeleting(false)
                setIsDeleteModalOpen(false)
            })
        })
    }
    useEffect(() => {
        if (selectedOrganizations.length > 0 && deletionResult.operationSuccessful.length === selectedOrganizations.length) {
            setIsDeleting(false)
            updateData()
            setDeleteResultOpen(true)
        } else if (deletionResult.operationErrors.length > 0) {
            setErrorMessage(t('somethingWentWrong'))
            setDeleteResultOpen(true)

        }
    }, [deletionResult])

    const handleRowClick = (selectedOrganization: Organization) => {
        const params = {
            selectedOrganization: selectedOrganization
        }
        navigate('detail', { state: params })
    }

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const searchedText = event.target.value
        setFilteredOrganizations(searchedText.trim().length > 0 ?
            organizations.filter(organization => {
                return organization.name.toLowerCase()
                    .includes(searchedText)
                    || organization.groupName?.toLowerCase()
                        .includes(searchedText)
            }) :
            organizations
        )
    }
    const deleteModal = <>
        {!deleteResultOpen && isDeleteModalOpen ?
            <Modal
                closable={false}
                className={'modal'}
                open={isDeleteModalOpen}
                footer={
                    <>
                        <Button disabled={isDeleting} className='yellowOutlinedCancelButton' onClick={() => setIsDeleteModalOpen(false)}>
                            {t('cancel')}
                        </Button>
                        <Button loading={isDeleting} className={'blackFillPositiveButton'} onClick={handleDelete}>
                            {t('delete')}
                        </Button>
                    </>
                }>
                <AlertIcon></AlertIcon>
                <p><Trans t={t} i18nKey="deleteOrganizationQuestion" count={selectedOrganizations.length} /></p>
            </Modal>
            :
            <Modal
                closable={false}
                className={'modal'}
                open={deleteResultOpen}
                footer={
                    <>
                        <Button className={'blackFillPositiveButton'} onClick={() => {
                            setDeleteResultOpen(false)
                            setDeletionResult({ operationErrors: [], operationSuccessful: [] })
                        }
                        }>
                            OK
                        </Button>
                    </>
                }>

                {deletionResult.operationErrors.length > 0 ?
                    <>
                        <AlertIcon></AlertIcon>
                        <p>{errorMessage}</p>
                        <ul>
                            {deletionResult.operationErrors.map(error => (
                                <li key={error.pk}>{organizations.find(org => org.pk === error.pk)?.name}</li>
                            ))}
                        </ul>
                    </>
                    :
                    <div>
                        <CheckMarkIcon />
                        <p>{t('organizationDeleted')}</p>
                    </div>
                }
            </Modal>
        }
    </>
    return (
        <div className={styles.container}>
            <div className={styles.titleRowContainer}>
                <TitleWithSubtitle title={t('organizations')} />
                <div className={styles.buttonsContainer}>
                    <Button
                        className={`blackFillPositiveButton ${styles.deleteButton}`}
                        disabled={selectedOrganizations.length === 0}
                        onClick={() => setIsDeleteModalOpen(true)}
                    >
                        {t('delete')}
                    </Button>
                    <Button
                        icon={<OrganizationIcon />}
                        className={`yellowFillPositiveButton ${styles.newOrganizationButton}`}
                        onClick={handleCreateOrganization}
                    >
                        {t('newOrganization')}
                    </Button>
                </div>
            </div>
            <div className={styles.searchContainer}>
                <Search
                    onChange={handleSearch}
                    allowClear
                    className={searchStyles.search}
                    placeholder={t('searchOrganizations')}></Search>
            </div>
            <div className={styles.tableContainer}>

                <OrganizationsTable
                    data={filteredOrganizations}
                    loaded={!isLoading}
                    onSelect={handleRowSelect}
                    onRowClick={handleRowClick} />
            </div>
            {deleteModal}
        </div>

    )
}

export default Organizations