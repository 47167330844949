import { Skeleton } from 'antd'
import './customTooltip.css'

interface PropsType {
    height: number
}


const CustomSkeleton = (props: PropsType) => {

    return (
        <Skeleton.Button 
            active={true}  
            block={true}
            style={{
                height: props.height,
                borderRadius: '5px'
            }} />    
    )
}

export default CustomSkeleton
