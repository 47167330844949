import { Button, Space } from 'antd'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as EmailIcon } from '../../../../../assets/email.svg'
import { ReactComponent as NoteIcon } from '../../../../../assets/note.svg'
import { ReactComponent as PhoneIcon } from '../../../../../assets/phone.svg'
import { ReactComponent as SmsIcon } from '../../../../../assets/sms.svg'
import { ReactComponent as WhatsappIcon } from '../../../../../assets/whatsapp.svg'
import { ContactAction } from './ContactActionsDetail/ContactActionComponent/ContactActionComponent'
import styles from './ContactActionsLogComponent.module.css'

interface ContactActionsLogComponentProps {
    onClick: (value: { open: boolean, action: ContactAction }) => void
}
export const ContactActionsLogComponent = (props: ContactActionsLogComponentProps): ReactElement => {
    const [t] = useTranslation('translations')
    return (
        <>
            <Space wrap={true}>
                <Button
                    onClick={() => props.onClick({ open: true, action: ContactAction.CALL })}
                    icon={<PhoneIcon />}
                    className={styles.yellowOutlinedButton}>
                    {t('logCall')}
                </Button>
                <Button
                    onClick={() => props.onClick({ open: true, action: ContactAction.SMS })}
                    icon={<SmsIcon className={styles.messageIcon} />}
                    className={styles.yellowOutlinedButton}>
                    {t('logSms')}
                </Button>
                <Button
                    onClick={() => props.onClick({ open: true, action: ContactAction.EMAIL })}
                    icon={<EmailIcon />}
                    className={styles.yellowOutlinedButton}>
                    {t('logEmail')}
                </Button>
                <Button
                    onClick={() => props.onClick({ open: true, action: ContactAction.WHATSAPP })}
                    icon={<WhatsappIcon />}
                    className={styles.yellowOutlinedButton}>
                    {t('logWhatsapp')}
                </Button>
                <Button
                    onClick={() => props.onClick({ open: true, action: ContactAction.NOTE })}
                    icon={<NoteIcon />}
                    className={styles.yellowOutlinedButton}>
                    {t('logNote')}
                </Button>
            </Space>
        </>
    )
}