import i18n from '../../../../../i18n'


export enum Gender {
    MALE = 'M',
    FEMALE = 'F',
    NON_BINARY = 'N'
}

export const getGenderTranslation = (gender: Gender): string => {
    switch (gender) {
    case Gender.MALE:
        return i18n.t('male')
    case Gender.FEMALE:
        return i18n.t('female')
    case Gender.NON_BINARY:
        return i18n.t('nonBinary')
    }
}