import { Button, Flex, Form, Modal, notification } from 'antd'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { setTopbarTitle } from '../../../../../app/Reducers/TopbarSlice'
import { ReactComponent as ActionPopupButton } from '../../../../../assets/actionPopupButton.svg'
import { ReactComponent as OkIcon } from '../../../../../assets/checkMarkIcon.svg'
import { ReactComponent as AlertIcon } from '../../../../../assets/danger.svg'
import { ReactComponent as DeleteIcon } from '../../../../../assets/delete.svg'
import { ReactComponent as SaveIcon } from '../../../../../assets/save.svg'
import { ReactComponent as MessageIcon } from '../../../../../assets/sms.svg'
import CustomSkeleton from '../../../../../common/components/CustomSkeleton'
import PopupMenu from '../../../../../common/components/PopupMenu'
import BackButton from '../../../../../common/components/commonUI/BackButton'
import TitleWithSubtitle from '../../../../../common/components/commonUI/TitleWithSubtitle'
import { getFullName, isAppCustomer } from '../../../../../common/utils'
import { useMobileMediaQuery } from '../../../../../hooks/useMobileMediaQuery'
import { CustomerApiFactory, CustomerDetail, CustomerDetailCustomerListsInner } from '../../../../../service/api'
import { ContactFormComponent } from '../ContactForm/ContactFormComponent'
import styles from './ContactDetail.module.css'
import { ContactDetailFooter } from './ContactDetailFooter'

interface ContactDetailParams {
    selectedContact: CustomerDetail
}
export const ContactDetail = () => {
    const api = CustomerApiFactory()
    const isMobile = useMobileMediaQuery()
    const [t] = useTranslation('translations')
    const navigate = useNavigate()

    const location = useLocation()
    const selectedContact: CustomerDetail = (location.state as ContactDetailParams).selectedContact
    const [currentCustomer, setCurrentCustomer] = useState<CustomerDetail>(selectedContact)
    const [selectedLists, setSelectedLists] = useState<CustomerDetailCustomerListsInner[]>([])

    const [form] = Form.useForm()
    const [isEditing, setIsEditing] = useState<boolean>(false)

    const dispatch = useDispatch()

    const [popupVisible, setPopupVisible] = useState<boolean>(false)

    const [operationSuccessful, setOperationSuccessful] = useState<string | undefined>()
    const [deletionSuccessful, setDeletionSuccessful] = useState<boolean>(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [isOperating, setIsOperating] = useState<boolean>(false)

    const update = () => {
        api.getCustomerById(selectedContact.pk).then(response => {
            setCurrentCustomer(response.data)
        })
    }
    useEffect(() => {
        update()
    }, [selectedContact])

    const handleFinish = (values: any, enabled: boolean) => {
        setIsOperating(true)
        const customerValues: CustomerDetail = values as CustomerDetail
        const customer: CustomerDetail = { ...currentCustomer }
        customer.email = customerValues.email
        customer.firstName = customerValues.firstName
        customer.lastName = customerValues.lastName
        customer.birthDate = customerValues.birthDate ? customerValues.birthDate.valueOf() : undefined
        customer.gender = customerValues.gender
        customer.phone = customerValues.phone
        customer.zip = customerValues.zip
        customer.address = customerValues.address
        customer.city = customerValues.city
        customer.province = customerValues.province
        customer.country = customerValues.country
        customer.notes = customerValues.notes
        customer.enabled = enabled
        customer.metadata = customerValues.metadata
        customer.customerLists = selectedLists
        api.updateCustomer(selectedContact.pk, customer).then(response => {
            setOperationSuccessful(t('contactSaved'))
        }).catch((error: AxiosError) => {
            setIsError(true)
        }).finally(() => setIsOperating(false))
    }

    const handleDelete = () => {
        setIsOperating(true)
        api.deleteCustomer(selectedContact.pk).then(() => {
            setDeletionSuccessful(true)
        }).catch(error => {
            setIsError(true)
        }).finally(() => {
            setIsDeleteModalOpen(false)
            setIsOperating(false)
        })
    }

    useEffect(() => {
        dispatch(setTopbarTitle(t('newContact')))
    }, [])

    return (
        <>
            {currentCustomer ?
                <div className={styles.container}>
                    <div className={styles.titleRow}>
                        {isMobile ?
                            <div className={styles.title}>
                                {t('newContact')}
                            </div>
                            :
                            <TitleWithSubtitle
                                itemName={isAppCustomer(currentCustomer.type) ? t('contactApp') : t('contactCrm')}
                                title={getFullName({ firstName: currentCustomer.firstName, lastName: currentCustomer.lastName })} />
                        }
                        <Flex gap={10}>
                            {isEditing ?
                                <Button
                                    disabled={isOperating}
                                    onClick={() => setIsEditing(false)}
                                    className={`yellowOutlinedCancelButton ${styles.button}`}>
                                    {t('cancel')}
                                </Button>
                                :
                                <Button
                                    onClick={() => setIsEditing(true)}
                                    className={`blackFillPositiveButton ${styles.button}`}>
                                    {t('edit')}
                                </Button>}
                            {isEditing ?
                                <Button
                                    loading={isOperating}
                                    className={`yellowFillPositiveButton ${styles.button}`}
                                    icon={<SaveIcon />}
                                    onClick={() => form.submit()}>
                                    {t('saveContact')}
                                </Button>
                                :
                                <Button
                                    className={`yellowFillPositiveButton ${styles.button}`}
                                    icon={<MessageIcon className={styles.messageIcon} />}
                                    onClick={() => notification.info({
                                        message: 'send'
                                    })}>
                                    {t('sendMessage')}
                                </Button>
                            }
                            <PopupMenu
                                content={[
                                    {
                                        key: '1',
                                        label:
                                            <div className={styles.delete}>
                                                <DeleteIcon className={styles.deleteIcon} />
                                                {t('delete')}
                                            </div>,
                                        onClick: () => setIsDeleteModalOpen(true)

                                    },
                                ]}
                                popupVisible={popupVisible}
                                placement='bottom'>
                                <Button
                                    disabled={isOperating}
                                    icon={<ActionPopupButton />}
                                    shape='circle'
                                    className={`${styles.popupButton} ${styles.button}`}
                                />
                            </PopupMenu>
                        </Flex>
                    </div >
                    <BackButton />
                    <ContactFormComponent
                        onListChange={(lists) => setSelectedLists(lists)}
                        disabled={!isEditing}
                        handleFinish={(values, enabled) => handleFinish(values, enabled)}
                        form={form}
                        selectedContact={currentCustomer} />
                    <div className={styles.contactFooter}>
                        <ContactDetailFooter selectedCustomer={currentCustomer} />
                    </div>
                    <Modal
                        closable={false}
                        className={'modal'}
                        open={isDeleteModalOpen}
                        footer={
                            <>
                                <Button
                                    disabled={isOperating}
                                    onClick={() => setIsDeleteModalOpen(false)}
                                    className={'yellowOutlinedCancelButton'}>
                                    {t('cancel')}
                                </Button>
                                <Button
                                    loading={isOperating}
                                    className={'blackFillPositiveButton'}
                                    onClick={() => handleDelete()}>
                                    {t('delete')}
                                </Button>
                            </>
                        }>
                        <AlertIcon></AlertIcon>
                        {t('deleteContactQuestion')}
                    </Modal>
                    <Modal
                        closable={false}
                        className={'modal'}
                        open={operationSuccessful !== undefined}
                        footer={
                            <>
                                <Button className={'blackFillPositiveButton'} onClick={() => {
                                    update()
                                    setIsEditing(false)
                                    setOperationSuccessful(undefined)
                                }}>
                                    OK
                                </Button>
                            </>
                        }>
                        <OkIcon></OkIcon>
                        <p>{operationSuccessful}</p>
                    </Modal>
                    <Modal
                        closable={false}
                        className={'modal'}
                        open={deletionSuccessful}
                        footer={
                            <>
                                <Button className={'blackFillPositiveButton'} onClick={() => {
                                    navigate(-1)
                                    setIsEditing(false)
                                    setDeletionSuccessful(false)
                                }}>
                                    OK
                                </Button>
                            </>
                        }>
                        <OkIcon></OkIcon>
                        <p>{t('contactDeleted')}</p>
                    </Modal>
                    <Modal
                        closable={false}
                        className={'modal'}
                        open={isError}
                        footer={
                            <>
                                <Button className={'blackFillPositiveButton'} onClick={() => setIsError(false)}>
                                    OK
                                </Button>
                            </>
                        }>
                        <AlertIcon></AlertIcon>
                        <p>{t('somethingWentWrong')}</p>
                    </Modal>
                </div>
                :
                <CustomSkeleton height={400} />
            }
        </>
    )
}