import { useMediaQuery } from 'react-responsive'

export const MOBILE_WIDTH = 992
export const useMobileMediaQuery = () => {

    const isMobile = useMediaQuery({
        query: `(max-width: ${MOBILE_WIDTH}px)`
    })

    return isMobile
}