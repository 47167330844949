
export interface OperationStatus {
    operationErrors: { pk: number, error: string }[],
    operationSuccessful: number[]
}

export enum UserRole {
    ADMIN = 'ROLE_ADMIN',
    FINANCIAL = 'ROLE_FINANCIAL',
    OPERATOR = 'ROLE_OPERATOR',
    USER = 'ROLE_USER'
}

export enum UserError {
    ERROR_404 = 404,
    ERROR_409 = 409,
    ERROR_403 = 403
}
