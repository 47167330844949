import { Tooltip } from 'antd'
import { TooltipPlacement } from 'antd/es/tooltip'
import './customTooltip.css'

interface PropsType {
    text: string,
    placement: TooltipPlacement
    children?: React.ReactNode
}

const CustomTooltip = (props: PropsType) => {
    return (
        <Tooltip
            placement={props.placement}
            trigger={'hover'} 
            title={props.text}>
            {props.children}
        </Tooltip>
    )
}

export default CustomTooltip
