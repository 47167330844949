import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface TopbarState {
    title?: string
}

const initialState: TopbarState = {
}

export const topbarSlice = createSlice({
    name: 'topbar',
    initialState,
    reducers: {
        setTopbarTitle: (state, action: PayloadAction<string>) => {
            state.title = action.payload
        },
        resetTopbarTitle: (state, action: PayloadAction<undefined>) => {
            state = initialState
        }
    }
})

export const { setTopbarTitle, resetTopbarTitle } = topbarSlice.actions

export default topbarSlice.reducer