import dayjs from 'dayjs'
import { formatDate, isAppCustomer } from '../../../../../common/utils'
import i18n from '../../../../../i18n'
import { ListCustomerModel } from './ListDetail'

export const createListExportData = (exportData: ListCustomerModel[]) => {
    return exportData.map(customer => {
        const registrationDate = customer.registrationDate !== undefined
            ? formatDate(dayjs(customer.registrationDate), 'DD/MM/YYYY')
            : ''
        const registrationType = isAppCustomer(customer.type)
            ? i18n.t('app')
            : i18n.t('contactCrm')
        return {
            [i18n.t('name')]: customer.fullName ?? '',
            [i18n.t('email')]: customer.email,
            [i18n.t('city')]: customer.city,
            [i18n.t('phone')]: customer.phone,
            [i18n.t('registrationType')]: registrationType,
            [i18n.t('registrationDate')]: registrationDate
        }
    })
}