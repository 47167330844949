import { PersonName } from '../common/utils'
import { CustomerDetail } from './api'

export const API_URL = '/showtime-web-api'
export const IS_LOGGED = 'isLogged'

export interface Setting {
    key: string
    value: string
}

export interface Notification {
    customer: CustomerDetail
    date: number
    type: string
}

export interface DashboardItemGroup {
    title: string
    items: DashboardItem[]
}
export interface DashboardItem {
    pk: number
    name: string
}

export interface Order {
    customerName: PersonName,
    userUrl?: string,
    showName: string,
    showDate: number,
    ticketsCount: number,
    totalPrice: number,
    purchaseDate: number
}

export interface SoldQuantity {
    date: number,
    amount: number
}

export interface SoldQuantityItem {
    count: number
    showName: string
    status: ShowStatus
}

export interface ChartItem {
    types: string[]
    data: (string | number)[][]
}

export interface ChartItem {
    types: string[]
    data: (string | number)[][]
}

export interface ChartItem {
    types: string[]
    data: (string | number)[][]
}

export interface ChartItem {
    types: string[]
    data: (string | number)[][]
    labels: string[]
}

export interface ShowItem {
    pk: number
    title: string
    reruns: ShowRerunItem[]
}

export interface ShowRerunItem {
    pk: number
    date: number
}
export interface TicketResultItem {
    successfulCodes: string[]
    failedCodes: FailedCodesItem[]
}
export interface FailedCodesItem {
    code: string
    error: string
}

export enum ShowStatus {
    Finished = 0,
    Ongoing = 1,
    Future = 2
}

export interface ErrorResponse {
    type: string
    status: number
    title: string
}