import { Drawer, Layout, notification } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { isAdminDashboard } from '../../App'
import { setConfig } from '../../app/Reducers/DashboardSlice'
import { setCurrentUser } from '../../app/Reducers/UserInfoSlice'
import '../../common/components/Popover.css'
import { AuthenticationApiFactory, User } from '../../service/api'
import { DashboardSetting } from '../dashboard/newDashboard/DashboardModel'
import { Topbar } from './Header/Topbar'
import styles from './Main.module.css'
import { Sider } from './Sider/Sider'
import { TopicMenu } from './TopicMenu'
import { MainRoutes } from './routing'


export const safelyParseJson = (json: string): DashboardSetting[] | null => {
    let adminDashboardConfiguration: DashboardSetting[] | null = null
    try {
        adminDashboardConfiguration = JSON.parse(json)
    } catch (e) {
        console.log(e)
    }
    return adminDashboardConfiguration
}

export const Main = (): ReactElement => {
    const api = AuthenticationApiFactory()
    const [t] = useTranslation('translations')
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false)
    const [user, setUser] = useState<User>()
    const [loading, setLoading] = useState<boolean>(true)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        api.getCurrentUser().then(response => {
            const user = response.data
            dispatch(setCurrentUser(user))
            setUser(user)
            const adminDashboard = user.application.settings.find(setting => setting.key === 'adminDashboard')
            if (isAdminDashboard(adminDashboard?.value)) {
                const adminDashboardSetting = user.application.settings.find(setting => setting.key === 'adminDashboardConfiguration')
                const adminDashboardConfiguration: DashboardSetting[] | null = safelyParseJson(adminDashboardSetting?.value ?? '')
                if (adminDashboardConfiguration != null) {
                    adminDashboardConfiguration.forEach(c => {
                        if (c.roles.includes(user.role)) {
                            dispatch(setConfig(c.config))
                        }
                    })
                } else {
                    dispatch(setConfig(undefined))
                }
            }
        }).catch(error => {
            if (error.response?.status === 401) {
                api.logout().then(() => {
                    localStorage.clear()
                    navigate('/')
                })
            }
            notification.error({
                message: t('error'),
                description: error.message
            })
        }).finally(() => setLoading(false))
    }, [])

    const handleMenuClick = () => {
        setDrawerVisible(false)
    }

    const getTopicMenu = () => {
        return (
            <TopicMenu
                onMenuChange={handleMenuClick}
                settings={user!.application.settings}
                userRole={user!.role} />
        )
    }
    return (
        <>
            {(!loading && user) &&
                < Layout className={styles.container} >
                    <Sider menu={getTopicMenu()} />
                    <Layout>
                        <Header className={styles.header}>
                            <Topbar
                                drawerVisible={drawerVisible}
                                user={user}
                                changeDrawerState={() => setDrawerVisible(prevState => !prevState)} />
                        </Header>
                        <Content style={{ position: 'relative' }}>
                            <Drawer
                                maskClassName={styles.drawerMask}
                                getContainer={false}
                                width={'55%'}
                                size='default'
                                className={styles.drawer}
                                placement="right"
                                closable={false}
                                open={drawerVisible}
                            >
                                {getTopicMenu()}
                            </Drawer>
                            <div className={styles.contentContainer}>
                                <div style={{ display: 'flex', flexDirection: 'column' as const, flexGrow: 1 }}>
                                    <MainRoutes role={user.role} settings={user.application.settings} />
                                </div>
                            </div>
                        </Content>
                    </Layout>
                </Layout >
            }
        </>
    )
}