import { Button, DatePicker, Form, Modal, Select, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { ReactElement, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { ReactComponent as AddIcon } from '../../../assets/add.svg'
import { ReactComponent as AlertIcon } from '../../../assets/alert.svg'
import { ReactComponent as ArrowDown } from '../../../assets/arrowDown.svg'
import { ReactComponent as DangerIcon } from '../../../assets/danger.svg'
import { ReactComponent as EditIcon } from '../../../assets/edit.svg'
import { ReactComponent as MoreIcon } from '../../../assets/more_vertical.svg'
import '../../../common/commonCss/buttons.css'
import '../../../common/commonCss/modal.css'
import '../../../common/commonCss/tableView.css'
import CustomSkeleton from '../../../common/components/CustomSkeleton'
import PopupMenu from '../../../common/components/PopupMenu'
import BackButton from '../../../common/components/commonUI/BackButton'
import TitleWithSubtitle from '../../../common/components/commonUI/TitleWithSubtitle'
import { formatDate } from '../../../common/utils'
import { Card, CardsApiFactory, OrganizationsApiFactory, Place, Visit } from '../../../service/api'
import styles from './CardDetail.module.css'

const { Option } = Select

interface CardDetailParams {
    selectedCard: Card
}

const CardDetail = (): ReactElement => {
    const api = CardsApiFactory()
    const organizationApi = OrganizationsApiFactory()
    const [t, i18n] = useTranslation('translations')
    const location = useLocation()
    const selectedCard: Card = (location.state as CardDetailParams).selectedCard
    const [loaded, setLoaded] = useState<boolean>(false)
    const [visits, setVisits] = useState<Visit[]>([])
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [poiList, setPoiList] = useState<Place[]>([])

    const [selectedPoi, setSelectedPoi] = useState<Place>()
    const [selectedVisitDate, setSelectedVisitDate] = useState<dayjs.Dayjs>()
    const [isSendingRequest, setIsSendingRequest] = useState<boolean>(false)
    const [isResponseModalOpen, setIsResponseModalOpen] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)

    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
    const [defaultPoi, setDefaultPoi] = useState<Place>()
    const [defaultVisitDate, setDefaultVisitDate] = useState<dayjs.Dayjs>()

    const [formKey, setFormKey] = useState<number>(0)

    useEffect(() => {
        updateData()
    }, [])

    const resetValues = () => {
        setSelectedPoi(undefined)
        setSelectedVisitDate(undefined)
        setDefaultPoi(undefined)
        setDefaultVisitDate(undefined)
        setIsModalOpen(false)
        setFormKey(prevKey => prevKey + 1)
    }
    const updateData = () => {
        resetValues()
        api.getCardDetails(selectedCard.pk).then(response => {
            setVisits(response.data.visits)
            setLoaded(true)
        })
        organizationApi.getAssociatedPoiList().then((response) => {
            setPoiList(response.data)
        })
    }

    const getPoiByPk = (poiPk: string): (Place | undefined) => {
        return poiList.find(poi => poi.pk.toString() === poiPk)
    }
    const columns: ColumnsType<Visit> = [
        {
            title: t('visitedMuseum') + ` (${visits.length})`,
            dataIndex: 'name',
            key: 'name',
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name)
            }
        },
        {
            title: t('dateTime'),
            dataIndex: 'date',
            key: 'date',
            render: (datetime: dayjs.Dayjs) => (
                <>{formatDate(dayjs(datetime), 'DD MMM YYYY HH:mm')}</>
            ),
            sorter: {
                compare: (a, b) => a.date.valueOf() - b.date.valueOf()
            }
        },
        {
            title: ' ',
            dataIndex: '',
            key: 'x',
            render: (value, record) => (
                <PopupMenu
                    content={[{
                        key: 1,
                        onClick: () => {
                            const poi = getPoiByPk(record.pk.toString())
                            setSelectedPoi(poi)
                            setDefaultPoi(poi)
                            setDefaultVisitDate(dayjs(record.date))
                            setIsModalOpen(true)
                        },
                        label: (<><div className={styles.popupItem}><EditIcon className={styles.popopItemIcon} />{t('editDateTime')}</div></>)
                    }]}
                    popupVisible={isPopupOpen}
                    placement='bottomRight'
                >
                    <Button
                        type='text'
                        shape='circle'
                        icon={<MoreIcon />}
                        className={styles.button}
                    />
                </PopupMenu>
            )
        }
    ]

    const handleRequest = () => {
        setIsSendingRequest(true)
        if (defaultPoi && defaultVisitDate) {
            api.updateVisitDate(selectedCard.pk, defaultPoi!.pk, selectedVisitDate!.valueOf()).then(response => {
                setIsResponseModalOpen(true)
            }).catch((error: AxiosError) => {
                setIsError(true)
            }).finally(() => {
                setIsModalOpen(false)
                setIsSendingRequest(false)
            })
        } else {
            api.insertVisit(selectedCard.pk, { poi: { pk: selectedPoi!.pk }, visitDate: selectedVisitDate!.valueOf() }).then(response => {
                setIsResponseModalOpen(true)
            }).catch((error: AxiosError) => {
                setIsError(true)
            }).finally(() => {
                setIsModalOpen(false)
                setIsSendingRequest(false)
            })
        }
    }

    return (
        <div className={styles.container}>
            <div>
                <div className={styles.headContainer}>
                    <TitleWithSubtitle title={selectedCard.code} itemName={i18n.t(`translations:${selectedCard.status}`)} />
                    <Button
                        onClick={() => setIsModalOpen(true)}
                        icon={<AddIcon />}
                        className={`yellowFillPositiveButton ${styles.addButton}`}>
                        {t('addVisit')}
                    </Button>
                </div>
                <BackButton></BackButton>
                <div className={styles.userInfoContainer}>
                    <span className={styles.userInfo}>{`${selectedCard.customer?.name ?? ''} | ${selectedCard.customer?.email ?? ''} | ${selectedCard.minors ?? ''} ${t('minorEntrances')}`}</span>
                </div>
            </div>
            <Modal
                className='modal'
                closable={false}
                open={isModalOpen}
                footer={
                    <>
                        <Button
                            onClick={resetValues}
                            className={'yellowOutlinedCancelButton'}>
                            {t('cancel')}
                        </Button>
                        <Button
                            loading={isSendingRequest}
                            disabled={selectedPoi == undefined || selectedVisitDate == undefined}
                            onClick={() => handleRequest()}
                            className={'yellowFillPositiveButton'}>
                            {defaultPoi ? t('editDateTime') : t('addVisitedMuseum')}
                        </Button>
                    </>
                }>
                <Form
                    key={formKey}
                    onFinish={() => {
                        handleRequest()
                    }}
                    requiredMark={false}
                    layout='vertical'
                    className={styles.form}>
                    <Form.Item
                        className={defaultPoi !== undefined ? styles.disabledLabel : undefined}
                        label={t('addVisitedMuseum')}
                        rules={[
                            {
                                required: true,
                                message: ''
                            }
                        ]}
                    >
                        <Select
                            disabled={defaultPoi !== undefined}
                            value={selectedPoi?.name}
                            onChange={(value) => {
                                setSelectedPoi(getPoiByPk(value))
                            }}
                            suffixIcon={<ArrowDown />}
                            className={styles.select}>
                            {poiList.map(poi => {
                                return <Option key={poi.pk}>{poi.name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t('dateTime')}
                        name={'visitDate'}
                        rules={[
                            {
                                required: true,
                                message: ''
                            }
                        ]}>
                        <DatePicker
                            defaultValue={defaultVisitDate}
                            allowClear={false}
                            suffixIcon={<ArrowDown />}
                            className={styles.datePicker}
                            showTime
                            onChange={value => {
                                if (value) {
                                    setSelectedVisitDate(value)
                                }
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                className='modal'
                closable={false}
                open={isResponseModalOpen}
                footer={
                    <>
                        <Button
                            onClick={() => {
                                updateData()
                                setIsResponseModalOpen(false)
                            }}
                            className={'blackFillPositiveButton'}>
                            {'OK'}
                        </Button>
                    </>
                }>
                <AlertIcon />
                <p>
                    <Trans>{defaultPoi ? t('updatedVisit', { name: selectedPoi?.name }) : t('addedMuseum', { name: selectedPoi?.name })}</Trans>
                </p>
            </Modal>
            <Modal
                className='modal'
                closable={false}
                open={isError}
                footer={
                    <>
                        <Button
                            onClick={() => {
                                updateData()
                                setIsError(false)
                            }}
                            className={'blackFillPositiveButton'}>
                            {'OK'}
                        </Button>
                    </>
                }>
                <DangerIcon />
                <p>
                    {t('somethingWentWrong')}
                </p>
            </Modal>
            <div className={styles.tableContainer}>
                {!loaded ?
                    <CustomSkeleton height={400}></CustomSkeleton>
                    :
                    <>
                        <Table
                            rowKey={record => record.pk}
                            dataSource={visits}
                            columns={columns}
                            className={`tableView ${styles.table}`}
                            showSorterTooltip={false}
                            pagination={{
                                position: ['bottomCenter'],
                                hideOnSinglePage: visits.length <= 10,
                                showSizeChanger: true,
                                style: {
                                    marginTop: '45px'
                                },
                                responsive: true,
                                showTitle: false
                            }}
                        />
                    </>}
            </div>
        </div>
    )
}
export default CardDetail