import Title from 'antd/es/typography/Title'
import classNames from './TitleWithSubtitle.module.css'
interface PropsType {
    title: string,
    items?: number,
    itemName?: string
}

const TitleWithSubtitle = (props: PropsType) => {
    return (
        <div className={classNames.titleContainer}>
            <Title level={3} className={classNames.title}>{props.title}</Title>
            {(props.items !== undefined || props.itemName !== undefined) &&
                <span className={classNames.itemsCount}>
                    &nbsp;&nbsp;&bull;&nbsp; {props.items} {props.itemName !== undefined && props.itemName}
                </span>
            }
        </div>
    )
}

export default TitleWithSubtitle