import { Segmented } from 'antd'
import { SegmentedValue } from 'antd/lib/segmented'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setEndDate, setSelectedPresetValue, setStartDate } from '../../../app/Reducers/DashboardSlice'
import { RootState } from '../../../app/store'
import { useMobileMediaQuery } from '../../../hooks/useMobileMediaQuery'
import styles from './datePresetBar.module.css'

interface PropsType {
    disabled: boolean
}

export const NO_SELECTION_VALUE = ''

const DatePresetBar = (props: PropsType) => {
    const isMobile = useMobileMediaQuery()
    const [t] = useTranslation('translations')
    const dispatch = useDispatch()
    const dateState = useSelector((state: RootState) => state.dashboardState)
    const [value, setValue] = useState<SegmentedValue>(dateState.selectedPresetValue)

    useEffect(() => {
        setValue(dateState.selectedPresetValue)
    }, [dateState.selectedPresetValue])

    const fullOptions: string[] = [
        t('last12months'),
        t('lastMonth'),
        t('last30days'),
        t('lastWeek'),
        t('last7days'),
        t('yesterday'),
        t('today')
    ]

    const mobileOptions: string[] = [
        t('30Days'),
        t('7Days'),
        t('yesterday'),
        t('today')
    ]

    const handleChange = (value: SegmentedValue) => {
        let startDate = dayjs()
        let endDate = startDate
        switch (value) {
        case t('last12months'):
            startDate = dayjs().subtract(1, 'year').hour(0).minute(0).second(0)
            endDate = dayjs().subtract(1, 'days').hour(23).minute(59).second(59)
            break
        case t('today'): {
            startDate = dayjs().hour(0).minute(0).second(0)
            endDate = dayjs()
            break
        }
        case t('yesterday'): {
            startDate = dayjs().subtract(1, 'days').hour(0).minute(0).second(0)
            endDate = dayjs().subtract(1, 'days').hour(23).minute(59).second(59)
            break
        }
        case t('lastWeek'): {
            // start week on Monday
            startDate = dayjs().subtract(7, 'days').startOf('week').hour(0).minute(0).second(0)
            // end week on Sunday
            endDate = dayjs().subtract(7, 'days').endOf('week').hour(23).minute(59).second(59)
            break
        }
        case t('lastMonth'): {
            startDate = dayjs().subtract(1, 'months').startOf('month').hour(0).minute(0).second(0)
            endDate = dayjs().subtract(1, 'months').endOf('month').hour(23).minute(59).second(59)
            break
        }
        case t('last7days'):
        case t('7Days'): {
            startDate = dayjs().subtract(7, 'days').hour(0).minute(0).second(0)
            endDate = dayjs().subtract(1, 'days').hour(23).minute(59).second(59)
            break
        }
        case t('last30days'):
        case t('30Days'): {
            startDate = dayjs().subtract(31, 'days').hour(0).minute(0).second(0)
            endDate = dayjs().subtract(1, 'days').hour(23).minute(59).second(59)
            break
        }
        default: {
            break
        }
        }
        dispatch(setStartDate(startDate))
        dispatch(setEndDate(endDate))
        dispatch(setSelectedPresetValue(value.toString()))
    }

    return (
        <Segmented
            block
            className={styles.datePresetBar}
            options={isMobile ? mobileOptions : fullOptions}
            onChange={handleChange}
            defaultValue={value}
            value={value}
            disabled={props.disabled} />
    )
}

export default DatePresetBar
