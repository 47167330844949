import { LoadingOutlined } from '@ant-design/icons'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import '../../../../../common/commonCss/tableView.css'
import CustomSkeleton from '../../../../../common/components/CustomSkeleton'
import { formatDate } from '../../../../../common/utils'
import styles from './WarehouseTableComponent.module.css'
import { Batch } from '../../../../../service/api'

interface WarehouseTableProps {
    data: Batch[]
    loading: boolean
    disabled: boolean
    onRowSelection: (selectedItem: Batch) => void
}
export const WarehouseTableComponent = ({ data, loading, disabled, onRowSelection }: WarehouseTableProps): ReactElement => {
    const [t] = useTranslation('translations')

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: Batch[]) => {
            onRowSelection(selectedRows[0])
        }
    }
    const columns: ColumnsType<Batch> = [
        {
            title: t('date'),
            dataIndex: 'date',
            render: (date: dayjs.Dayjs) => (
                <span>{formatDate(dayjs(date), 'DD MMM YYYY')}</span>
            ),
            sorter: (a, b) => dayjs(a.date).diff(dayjs(b.date))
        },
        {
            title: t('intervalStarting'),
            dataIndex: 'batchStart',
            sorter: (a, b) => a.batchStart - b.batchStart
        },
        {
            title: t('intervalEnding'),
            dataIndex: 'batchEnd',
            sorter: (a, b) => a.batchEnd - b.batchEnd
        },
        {
            title: t('total'),
            dataIndex: 'count',
            sorter: (a, b) => a.count - b.count
        },
    ]
    return (
        <div >
            {loading ?
                <CustomSkeleton height={150} />
                :
                <Table
                    rowKey={batch => batch.pk}
                    rowSelection={{ type: 'radio', ...rowSelection }}
                    columns={columns}
                    loading={{ indicator: <LoadingOutlined style={{ display: 'none' }} />, spinning: disabled }}
                    dataSource={data}
                    pagination={{
                        position: ['bottomCenter'],
                        hideOnSinglePage: data.length <= 10,
                        showSizeChanger: true,
                        style: {
                            marginTop: '45px'
                        },
                        responsive: true,
                        showTitle: false
                    }}
                    className={`${styles.customTable} tableView`} />
            }
        </div >
    )
}