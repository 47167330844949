import { Button, Modal } from 'antd'
import { ReactElement, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ReactComponent as OkIcon } from '../../assets/alert.svg'
import { ReactComponent as DangerIcon } from '../../assets/danger.svg'
import styles from './DeleteModalManager.module.css'

export enum ModalOptions {
    DELETE,
    SUCCESSFUL,
    ERROR,
    NONE
}
interface DeleteModalManagerProps {
    isDeleting: boolean
    modalType: ModalOptions
    modalText: string
    modalResultText: string
    onDelete: () => void
    onChange: (modalOption: ModalOptions) => void
}
export const DeleteModalManager = (props: DeleteModalManagerProps): ReactElement => {
    const [t] = useTranslation('translations')
    const [modalType, setModalType] = useState<ModalOptions>(ModalOptions.NONE)
    const [activeModal, setActiveModal] = useState<JSX.Element>()
    const createDeleteModal = () => {
        return (
            <Modal
                closable={false}
                className={'modal'}
                open={true}
                footer={
                    <>
                        <Button
                            loading={props.isDeleting}
                            className='yellowOutlinedCancelButton'
                            onClick={() => props.onChange(ModalOptions.NONE)}>
                            {t('cancel')}
                        </Button>
                        <Button
                            loading={props.isDeleting}
                            className={'blackFillPositiveButton'}
                            onClick={props.onDelete}>
                            {t('delete')}
                        </Button>
                    </>
                }>
                <DangerIcon />
                <div className={styles.modalBody}>
                    <Trans t={t}>{props.modalText}</Trans>
                </div>
            </Modal>
        )
    }
    const createErrorModal = () => {
        return (
            <>
                <Modal
                    closable={false}
                    className={'modal'}
                    open={true}
                    footer={
                        <>
                            <Button
                                className={'blackFillPositiveButton'}
                                onClick={() => props.onChange(ModalOptions.NONE)}>
                                OK
                            </Button>
                        </>
                    }>
                    <DangerIcon />
                    <p>{t('somethingWentWrong')}</p>
                </Modal>
            </>
        )
    }
    const createSuccessfulModal = () => {
        return (<>
            <Modal
                closable={false}
                className={'modal'}
                open={true}
                footer={
                    <>
                        <Button
                            className={'blackFillPositiveButton'}
                            onClick={() => props.onChange(ModalOptions.NONE)}>
                            OK
                        </Button>
                    </>
                }>
                <OkIcon />
                <div className={styles.modalBody}>
                    <Trans t={t}>{props.modalResultText}</Trans>
                </div>
            </Modal>
        </>
        )
    }

    useEffect(() => {
        switch (props.modalType) {
        case ModalOptions.DELETE:
            setActiveModal(createDeleteModal())
            break
        case ModalOptions.SUCCESSFUL:
            setActiveModal(createSuccessfulModal())
            break
        case ModalOptions.ERROR:
            setActiveModal(createErrorModal())
            break
        case ModalOptions.NONE:
        default: setActiveModal(<></>)
            break
        }
        setModalType(props.modalType)
    }, [props.modalType])

    return (
        <>
            {activeModal}
        </>
    )
}