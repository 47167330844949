import { Area, AreaConfig } from '@ant-design/plots'
import { Button } from 'antd'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import openDetailIcon from '../../../../assets/expand.svg'
import { Colors } from '../../../../common/Colors'
import CustomSkeleton from '../../../../common/components/CustomSkeleton'
import NoData from '../../../../common/components/NoData'
import ChartTooltip from '../../../../common/components/charts/ChartTooltip'
import ExportCsv from '../../../../common/components/charts/ExportCsv'
import i18n from '../../../../i18n'
import { DashboardApiFactory } from '../../../../service/api'
import NoSelection from '../../NoSelection'
import classStyle from '../dashboardGraphs.module.css'
import './lineChart.css'

export interface LineChartPropsType {
    title: string
    tooltip: string
    dataSource: string
    onDetailClick: (title: string, tooltip: string, dataSource: string, data: any[], columnLabels: string[]) => void
    onExportClick: (title: string, data: any[]) => void
}

export interface ItemTooltip {
    title: string,
    name: string,
    value: number,
}

const THOUSAND_SUFFIX = 'k'

const LineChart = (props: LineChartPropsType) => {
    const api = DashboardApiFactory()
    const [t] = useTranslation('translations')
    const dashboardState = useSelector((state: RootState) => state.dashboardState)
    const [lineChartData, setLineChartData] = useState<any[]>([])
    const [columnLabels, setColumnLabels] = useState<string[]>([])
    const [config, setConfig] = useState<AreaConfig>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [lineChartTotal, setLineChartTotal] = useState<number>(0)
    const tooltip: string = t(props.tooltip as unknown as TemplateStringsArray)
    const title: string = t(props.title as unknown as TemplateStringsArray)
    const error = dashboardState.selectedItemsIds.length === 0 ? true : false
    useEffect(() => {
        if (!error) {
            setIsLoading(true)
            const start = dashboardState.startDate.clone()
            const end = dashboardState.endDate.clone()
            api.getChartData(props.dataSource, {
                pks: dashboardState.selectedItemsIds,
                startDate: start.valueOf(),
                endDate: end.valueOf()
            }).then(response => {
                const data = response.data
                const label1 = i18n.t('translations:' + data.labels[0])
                const label2 = i18n.t('translations:' + data.labels[1])
                setColumnLabels([label1, label2])
                setLineChartData(data.data.map((item) => ({
                    [label1]: dayjs(item[0]).format('DD MMM'),
                    [label2]: item[1],
                })))
                setIsLoading(false)
            })
        }
    }, [dashboardState.selectedItemsIds, dashboardState.startDate, dashboardState.endDate])
    useEffect(() => {
        setLineChartTotal(_.sumBy(lineChartData, datum => datum[columnLabels[1]]))
        setConfig({
            data: lineChartData,
            xField: columnLabels[0],
            yField: columnLabels[1],
            // unlike width, height is not so flexible for the graph
            height: 140,
            xAxis: {
                tickCount: 7,
                tickLine: null,
                label: {
                    formatter: (value: string) => {
                        return value
                    },
                    style: {
                        fill: '#191919',
                        fontWeight: 700
                    },
                },
            },
            yAxis: {
                line: {
                    style: { stroke: Colors.white },
                },
                label: {
                    formatter: (value: string) => {
                        const num = parseInt(value)
                        return num >= 1000 ? `${num / 1000} ${THOUSAND_SUFFIX}` : value
                    }
                },
                position: 'right' as const
            },
            line: { color: Colors.yellow },
            areaStyle: { fill: Colors.yellow, fillOpacity: 0.2 },
            point: {
                color: Colors.yellow,
                size: 2,
                style: {
                    lineWidth: 2,
                }
            },
            tooltip: {
                marker: { fill: Colors.yellow },
                customContent: (title: string, items: ItemTooltip[]) => {
                    return items.length > 0 ? items[0].value.toString() : ''
                },
                showTitle: false,
            }
        })
    }, [lineChartData])
    const onDetailClick = () => {
        props.onDetailClick(title, tooltip, props.dataSource, lineChartData, columnLabels)
    }

    return (
        <div key={props.title}>
            {isLoading ?
                <CustomSkeleton height={280} />
                :
                <div className={classStyle.container}>
                    <div style={{
                        display: 'flex', alignItems: 'center'
                    }}>
                        <h1 className={classStyle.title}>
                            {title}
                            <ChartTooltip tooltipText={tooltip} />
                        </h1>
                        {!error && lineChartData.length > 0 &&
                            <div className={classStyle.more}>
                                <ExportCsv filename={title} data={lineChartData} isImage={true} />
                                <Button
                                    className={classStyle.graphIconButton}
                                    icon={<img src={openDetailIcon} />}
                                    onClick={onDetailClick} />
                            </div>
                        }
                    </div>
                    {!error && lineChartData.length > 0 &&
                        <p className={classStyle.totalValue}>{lineChartTotal}</p>}
                    <div className={classStyle.contentContainer}>
                        {(!error && lineChartData.length > 0 && config) &&
                            <div>
                                <Area
                                    {...config}
                                    style={{ width: '100%' }}
                                />
                            </div>
                        }
                        {(!error && lineChartData.length === 0) &&
                            <NoData style={{ height: '100%', position: 'relative', margin: '0 auto' }} />
                        }
                        {!!error &&
                            <NoSelection
                                imageStyle={{ width: '40px', height: '50px', marginBottom: '0px', marginRight: '8px' }}
                                style={{
                                    display: 'flex',
                                    height: '100%',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingBottom: '30px'
                                }} />
                        }
                    </div>
                </div>}
        </div>
    )
}

export default LineChart
