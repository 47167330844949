import { Button } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCSVDownloader } from 'react-papaparse'
import { ReactComponent as ActionPopupButton } from '../../../../assets/actionPopupButton.svg'
import exportData from '../../../../assets/export.svg'
import importData from '../../../../assets/import.svg'
import PopupMenu from '../../../../common/components/PopupMenu'
import { CustomerDetail } from '../../../../service/api'
import { createExportData } from './ContactUtils'
import styles from './ContactsPopupMenu.module.css'
interface PropsType {
    exportData: CustomerDetail[],
    onImportClick: () => void
}

const ContactsPopupMenu = (props: PropsType) => {
    const [t] = useTranslation('translations')
    const [popupVisible, setPopupVisible] = useState<boolean>(false)
    const { CSVDownloader } = useCSVDownloader()

    const handlePopupItemClicked = () => {
        setPopupVisible(!popupVisible)
    }

    const exportMenuItem = () => (
        <CSVDownloader
            filename={'contacts'}
            bom={true}
            data={() => createExportData(props.exportData)}
        >
            <>
                <img src={exportData} className='ant-menu-item-icon' />
                <span className='ant-menu-title-content'>{t('export')}</span>
            </>
        </CSVDownloader>
    )

    const importMenuItem = () => (
        <>
            <img src={importData} className='ant-menu-item-icon' />
            <span className='ant-menu-title-content'>{t('import')}</span>
        </>
    )

    return (
        <PopupMenu
            content={[
                {
                    key: '1',
                    label: exportMenuItem(),
                    onClick: () => {
                        handlePopupItemClicked()
                    }
                },
                {
                    key: '2',
                    label: importMenuItem(),
                    onClick: () => {
                        props.onImportClick()
                        handlePopupItemClicked()
                    }
                },
            ]}
            popupVisible={popupVisible}
            placement='bottomRight'>
            <div
                className={styles.popupContent}
                onClick={handlePopupItemClicked}>
                <Button
                    icon={<ActionPopupButton />}
                    shape='circle'
                    className={`${styles.popupButton}`}
                />
            </div>
        </PopupMenu>
    )
}

export default ContactsPopupMenu
