import { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import EditorToolbar, { formats } from './EditorToolbar'
import './editor.css'

interface editorProps {
    toolbarClassName: string
    description: string
    onChange: (value: string) => void
}

const modules = (toolbarId: string) => ({
    toolbar: {
        container: '#' + toolbarId,
        history: {
            delay: 500,
            maxStack: 100,
            userOnly: true
        }
    }
}
)

export const Editor = (props: editorProps) => {
    const [state, setState] = useState<string>('')
    const handleChange = (value: string) => {
        setState(value)
        props.onChange(value)
    }
    useEffect(() => {
        if (props.description) {
            setState(props.description)
        }
    }, [])

    return (
        <div className='text-editor'>
            <EditorToolbar id={props.toolbarClassName} />
            <ReactQuill
                className='editor'
                theme='snow'
                value={state}
                onChange={handleChange}
                placeholder={'...'}
                modules={modules(props.toolbarClassName)}
                formats={formats}
            />
        </div>
    )
}

export default Editor
