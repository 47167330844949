import { Button, notification } from 'antd'
import Search from 'antd/es/input/Search'
import { AxiosError } from 'axios'
import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as AddProductIcon } from '../../../assets/addProduct.svg'
import searchStyle from '../../../common/commonCss/Search.module.css'
import '../../../common/commonCss/buttons.css'
import TitleWithSubitile from '../../../common/components/commonUI/TitleWithSubtitle'
import styles from './Inventory.module.css'
import Inventorytable from './InventoryTable'
import { Product, ProductsApiFactory } from '../../../service/api'

const Inventory = (): ReactElement => {
    const api = ProductsApiFactory()
    const [t] = useTranslation('translations')
    const navigate = useNavigate()
    const [disabled, setDisabled] = useState(true)
    const [products, setProducts] = useState<Product[]>([])
    const [loaded, setLoaded] = useState<boolean>(false)
    const [filteredProducts, setFilteredProducts] = useState<Product[]>([])

    useEffect(() => {
        api.getProducts().then(response => {
            const products = response.data
            setProducts(products)
            setFilteredProducts(products)
            setLoaded(true)
        }).catch((error: AxiosError) => {
            notification.error({
                message: error.response?.status.toString(),
                description: error.message
            })
        })
    }, [])

    const handleRowClick = (selectedItem: Product) => {
        const params = {
            selectedProduct: selectedItem
        }
        navigate('detail', { state: params })
    }

    const handleSortChange = (sortedItems: Product[]) => {
        setFilteredProducts(sortedItems)
    }

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const searchedText = event.target.value.toLowerCase().trim()
        setFilteredProducts(searchedText.length > 0 ?
            products.filter(product => {
                return product.title.toLowerCase().includes(searchedText)
            }) : products)
    }

    return (
        <div className={styles.container}>
            <div className={styles.titleRowContainer}>
                <TitleWithSubitile title={t('inventory')} />
                <div className={styles.buttonsContainer}>
                    <Button
                        disabled={disabled}
                        className={`blackFillPositiveButton ${styles.moveToTrashButton}`}>
                        {t('moveToTrash')}
                    </Button>
                    <Button
                        icon={<AddProductIcon />}
                        className={`yellowFillPositiveButton ${styles.addProductButton}`}
                        onClick={() => navigate('../products', { replace: true })}
                    >
                        {t('manageProducts')}
                    </Button>
                </div>
            </div>
            <div className={styles.searchContainer}>
                <Search
                    placeholder={t('searchProducts')}
                    allowClear
                    className={searchStyle.search}
                    onChange={(event) => handleSearch(event)} />
            </div>
            <div className={styles.tableContainer}>
                <Inventorytable
                    data={filteredProducts}
                    loaded={loaded}
                    onRowClick={handleRowClick}
                    onSortChange={handleSortChange} />
            </div>
        </div >
    )
}

export default Inventory