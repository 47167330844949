import { notification } from 'antd'
import { t } from 'i18next'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import './App.css'
import { setConfig } from './app/Reducers/DashboardSlice'
import { setCurrentUser } from './app/Reducers/UserInfoSlice'
import { RootState } from './app/store'
import Dashboard from './pages/dashboard/newDashboard/Dashboard'
import { DashboardSetting } from './pages/dashboard/newDashboard/DashboardModel'
import { Login } from './pages/login/Login'
import { Main } from './pages/main/Main'
import { NotificationBadge, NotificationWidget } from './pages/notification/NotificationWidget'
import { AuthenticationApiFactory, User } from './service/api'
import { IS_LOGGED } from './service/model'

export function isUserLogged(): boolean {
    return localStorage.getItem(IS_LOGGED) === 'true'
}

export const isAdminDashboard = (adminDashboardValue = 'false'): boolean => {
    let result = false
    try {
        result = JSON.parse(adminDashboardValue)
    } catch (e) {
        console.log(e)
    }
    return result
}

export const safelyParseJson = (json: string): DashboardSetting[] | null => {
    let adminDashboardConfiguration: DashboardSetting[] | null = null
    try {
        adminDashboardConfiguration = JSON.parse(json)
    } catch (e) {
        console.log(e)
    }
    return adminDashboardConfiguration
}

const App = (): ReactElement => {
    const api = AuthenticationApiFactory()
    const dashboardState = useSelector((state: RootState) => state.dashboardState)
    const userState = useSelector((state: RootState) => state.currentUserState)
    const [adminDashboard, setAdminDashboard] = useState<boolean>()
    const dispatch = useDispatch()
    
    useEffect(() => {
        if (isUserLogged()) {
            if (userState.user.pk === -1) {
                api.getCurrentUser().then(response => {
                    const user = response.data
                    dispatch(setCurrentUser(user))
                }).catch(error => {
                    notification.error({
                        message: t('error'),
                        description: error.message
                    })
                })
            } else {
                const adminDashboard = userState.user.application.settings.find(setting => setting.key === 'adminDashboard')
                setAdminDashboard(isAdminDashboard(adminDashboard?.value))
                if (isAdminDashboard(adminDashboard?.value)) {
                    const user = userState.user
                    const adminDashboardSetting = user.application.settings.find(setting => setting.key === 'adminDashboardConfiguration')
                    const adminDashboardConfiguration: DashboardSetting[] | null = safelyParseJson(adminDashboardSetting?.value ?? '')
                    if (adminDashboardConfiguration !== null) {
                        adminDashboardConfiguration.forEach(c => {
                            if (c.roles.includes(user.role)) {
                                dispatch(setConfig(c.config))
                            }
                        })
                    } else {
                        dispatch(setConfig(undefined))
                    }
                }
            }
        }
    }, [userState.user])
    return (
        <>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/login' element={<Navigate to='/main' replace />} />
                <Route path='/notification-badge' element={
                    <RequireAuth>
                        <NotificationBadge />
                    </RequireAuth>
                } />
                <Route path='/notification-widget' element={
                    <RequireAuth>
                        <NotificationWidget />
                    </RequireAuth>
                } />
                <Route path='/main/*' element={
                    <RequireAuth>
                        <Main />
                    </RequireAuth>
                } />
                {adminDashboard &&
                    <>
                        <Route path='/dashboard' element={
                            <RequireAuth>
                                <Dashboard />
                            </RequireAuth>
                        } />
                    </>
                }
            </Routes>
        </>
    )
}

function Home() {
    return !isUserLogged() ? <Login /> : <Navigate to='/main' replace />
}

export function RequireAuth({ children }: { children: JSX.Element }) {
    const location = useLocation()
    return !isUserLogged() ? <Navigate to='/' state={{ from: location }} replace /> : children
}

export default App
