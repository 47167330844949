import Icon from '@ant-design/icons'
import { Button, Collapse } from 'antd'
import { UploadFile } from 'antd/lib'
import { ItemType } from 'rc-collapse/es/interface'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as AddVariantIcon } from '../../../../../assets/add.svg'
import { ReactComponent as YellowArrow } from '../../../../../assets/arrowDown.svg'
import { Colors } from '../../../../../common/Colors'
import '../../../../../common/commonCss/buttons.css'
import CustomSkeleton from '../../../../../common/components/CustomSkeleton'
import { ProductVariant, ProductsApiFactory } from '../../../../../service/api'
import CollapsibleHeader from './CollapsibleHeader'
import VariantForm from './VariantForm'
import styles from './collapsibleList.module.css'

interface collapsibleProps {
    productPk?: number
    variants?: ProductVariant[]
    onChange: (variantList: VariantPayload[]) => void
}

export interface VariantPayload {
    validation: boolean
    variant: ProductVariant
    file: UploadFile | undefined
}
const CollapsibleList = (props: collapsibleProps): ReactElement => {
    const api = ProductsApiFactory()
    const [t] = useTranslation('translations')
    const [items, setItems] = useState<ItemType[]>([])
    const [variantsDetails, setVariantsDetails] = useState<VariantPayload[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [activeKey, setActiveKey] = useState<number>()

    const panelStyle: React.CSSProperties = {
        marginBottom: '15px',
        background: Colors.lighterGray,
        border: '1px solid #DDDDDD',
        borderRadius: '4px',
    }

    useEffect(() => {
        setVariantsDetails([])
        if (props.variants && props.productPk) {
            setIsLoading(true)
            api.getAllVariants(props.productPk).then(response => {
                const variants = response.data
                setVariantsDetails(variants.map(variant => ({
                    validation: true,
                    variant: variant,
                    file: undefined
                })))
            }).finally(() => setIsLoading(false))
        }
    }, [props.variants, props.productPk])

    const handleVariantChange = (variantPayload: VariantPayload, index: number) => {
        const list = [...variantsDetails]
        list[index] = variantPayload
        setVariantsDetails(list)
    }
    useEffect(() => {
        setItems(variantsDetails.map((variantDetail, index) => ({
            key: index,
            label: variantDetail.variant.name,
            children: <VariantForm
                onChange={value => handleVariantChange(value, index)}
                handleClone={() => handleCloneVariant(index)}
                handleDelete={() => handleDeleteVariant(index)}
                variant={variantDetail}
                variantsList={variantsDetails.map(variantDetail => variantDetail.variant)} />,
            extra: <CollapsibleHeader
                title={variantDetail.variant.name}
                handleDeleteVariant={() => handleDeleteVariant(index)}
                handleCloneVariant={() => handleCloneVariant(index)} />,
            style: panelStyle
        })))
        props.onChange(variantsDetails)
    }, [variantsDetails])

    const handleDeleteVariant = (key: number) => {
        setVariantsDetails(variantsDetails.filter((item, index) => {
            return index !== key
        }))
    }

    const handleCloneVariant = (key: number) => {
        const variants: VariantPayload[] = JSON.parse(JSON.stringify(variantsDetails))
        const variant = variants.find((variantPayload, index) => index === key)!
        const cloneVariant: VariantPayload = {
            file: variant.file, validation: variant.validation, variant:
            {
                pk: null,
                duration: variant.variant.duration,
                image: variant.variant.image,
                linkedVariant: variant.variant.linkedVariant,
                locations: variant.variant.locations,
                name: `${variant.variant.name} (1)`,
                price: variant.variant.price,
                sku: variant.variant.sku
            }
        }
        setVariantsDetails(prevState => [...prevState, cloneVariant])
    }
    const handleAddVariant = () => {
        setVariantsDetails(prevState => ([...prevState, {
            validation: false,
            file: undefined,
            variant: {
                pk: null,
                name: '',
                price: null,
                sku: '',
                image: { id: null, imageUrl: null },
                locations: [],
                duration: null,
                linkedVariant: null
            }
        }]))
    }

    return (
        <>
            {isLoading ? < CustomSkeleton height={100} />
                :
                <div>
                    <Collapse
                        accordion
                        className={styles.collpasibleList}
                        expandIconPosition='end'
                        collapsible='header'
                        items={items}
                        activeKey={activeKey}
                        onChange={(key) => {
                            if (activeKey === Number(key) || activeKey === undefined) {
                                setActiveKey(undefined)
                            } else {
                                setActiveKey(Number(key))
                            }
                        }}
                        expandIcon={({ isActive }) => <YellowArrow rotate={isActive ? 90 : 0} />} />
                    <Button
                        icon={<Icon style={{ color: Colors.yellow }} component={AddVariantIcon} />}
                        className={`yellowFillPositiveButton  ${styles.commonButton} ${styles.primaryButton}`}
                        onClick={handleAddVariant}>
                        {t('addVariant')}
                    </Button>
                </div>
            }
        </>
    )
}

export default CollapsibleList