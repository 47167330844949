import { Tooltip } from 'antd'
import i18n from '../../../i18n'
import styles from './CustomRequiredMark.module.css'
export const CustomizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
    <>
        {label}
        {required &&
            <Tooltip title={i18n.t('requiredField')}>
                <span className={styles.requiredMark} style={{ color: 'red' }}>*</span>
            </Tooltip>
        }
    </>
)