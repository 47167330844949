import Icon from '@ant-design/icons'
import { Empty } from 'antd'
import { ReactComponent as NoSelectionIcon } from '../../assets/cloud-cross.svg'
import { useMobileMediaQuery } from '../../hooks/useMobileMediaQuery'

enum DescriptionPosition {
    Bottom,
    Right
}

interface NoSelectionProps {
    style?: React.CSSProperties
    className?: string
    imageStyle?: React.CSSProperties
    description?: {
        position?: DescriptionPosition
        text?: string
        style?: React.CSSProperties
        className?: string
    } | string
}

const NoSelection = ({ style, className, imageStyle, description }: NoSelectionProps) => {
    const isMobile = useMobileMediaQuery()
    const finalImageStyle = { fontSize: '40px', ...imageStyle }

    return (
        <>
            {!description || typeof description === 'string' ? (
                <Empty
                    style={style}
                    className={className}
                    image={<Icon component={NoSelectionIcon} />}
                    imageStyle={finalImageStyle}
                    description={(!isMobile && description) ? description : null}
                />
            ) : (
                <Empty
                    style={style}
                    className={className}
                    image={<Icon component={NoSelectionIcon} />}
                    imageStyle={finalImageStyle}
                    description={!isMobile ?
                        (<span style={description.style} className={description.className}>
                            {description.text}
                        </span>)
                        : null
                    }
                />
            )}
        </>
    )
}

export default NoSelection