import { Button, Input, notification } from 'antd'
import { ReactElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { sha512 } from 'sha512-crypt-ts'
import { logoutUser } from '../../app/Reducers/UserInfoSlice'
import showtimeLogo from '../../assets/showtime.svg'
import { Colors } from '../../common/Colors'
import '../../common/commonCss/buttons.css'
import { AuthenticationApiFactory } from '../../service/api'
import { IS_LOGGED } from '../../service/model'
import styles from './Login.module.css'


export const Login = (): ReactElement => {
    const api = AuthenticationApiFactory()
    const [t] = useTranslation('translations')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [rememberMe, setRememberMe] = useState(false)
    const [loginEnabled, setLoginEnabled] = useState(false)

    const navigate = useNavigate()
    const location = useLocation()

    const from = useMemo(() => {
        const state = location.state as { from: Location }
        if (state && state.from) {
            return '/main'
        }
        return '/login'
    }, [location])

    const year = new Date().getFullYear()

    useEffect(() => {
        setLoginEnabled(Boolean(username.trim() && password.trim()))
    }, [username, password])

    const handleLogin = () => {
        api.login(username, sha512.hex(password), rememberMe).then((response) => {
            navigate(from, { replace: true })
            localStorage.setItem(IS_LOGGED, 'true')
        }).catch(() => {
            localStorage.removeItem(IS_LOGGED)
            notification.error({
                message: t('error'),
                description: t('accessDenied'),
            })
        })
    }

    const handleKeyPress = () => {
        if (loginEnabled) {
            handleLogin()
        }
    }

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value)
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }

    const handleRememberMeChange = () => {
        setRememberMe((value) => !value)
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.contentContainer}>
                    <form noValidate autoComplete="off" className={styles.loginForm} >
                        <img src={showtimeLogo} className={styles.title} />

                        <div className={styles.formControls}>
                            <Input
                                className={styles.username}
                                placeholder="Username"
                                onChange={handleUsernameChange}
                                onPressEnter={handleKeyPress} />

                            <Input.Password
                                className={styles.password}
                                placeholder="Password"
                                onChange={handlePasswordChange}
                                onPressEnter={handleKeyPress} />

                            {/*<Checkbox
                                className={styles.rememberMe}
                                onChange={handleRememberMeChange}>
                                <span className={styles.rememberMeLabel}>{t('rememberMe')}</span>
                            </Checkbox>*/}

                            <Button className={'blackFillPositiveButton ' + styles.loginButton}
                                disabled={!loginEnabled}
                                onClick={handleLogin}>{t('login')}</Button>

                        </div>
                    </form>

                    <div className={styles.copyright}>
                        &copy;{year} Mango Mobile S.r.l. {t('allRightsReserved')}
                    </div>
                    <div className={styles.link}>
                        <a href="https://showtimezone.com" className='showtimezone'>www.showtimezone.com</a>
                    </div>
                    <div className={styles.version}>
                        <p>{process.env.REACT_APP_VERSION} ({process.env.REACT_APP_BUILD_NUMBER})</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export const Logout = (): ReactElement => {
    const api = AuthenticationApiFactory()
    const navigate = useNavigate()
    const [t] = useTranslation('translations')
    const dispatch = useDispatch()
    const handleLogout = () => {
        api.logout().then(() => {
            localStorage.clear()
            dispatch(logoutUser())
            navigate('/')
        })
    }

    return (
        <Button
            type="text"
            onClick={handleLogout}
            style={{
                color: Colors.black,
                borderRadius: 0,
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '11px',
                width: '100%'
            }}>{t('logout')}</Button>
    )

}
