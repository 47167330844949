import { Button, Col, Modal, Row, notification } from 'antd'
import Search from 'antd/lib/input/Search'
import { AxiosError } from 'axios'
import { Key, ReactElement, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as AddProductIcon } from '../../../assets/addProduct.svg'
import { ReactComponent as DangerIcon } from '../../../assets/danger.svg'
import TitleWithSubtitle from '../../../common/components/commonUI/TitleWithSubtitle'
import { Product, ProductsApiFactory } from '../../../service/api'
import { OperationStatus } from '../../users/AdminUtils'
import { DeleteModalManager, ModalOptions } from '../DeleteModalManager'
import TableView from './ProductTable'
import styles from './Products.module.css'

const Products = (): ReactElement => {
    const api = ProductsApiFactory()
    const [t] = useTranslation('translations')
    const navigate = useNavigate()

    const [filteredProducts, setFilteredProducts] = useState<Product[]>([])
    const [selectedKeys, setSelectedKeys] = useState<Key[]>([])
    const [products, setProducts] = useState<Product[]>([])
    const [loaded, setLoaded] = useState<boolean>(false)

    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const [modalType, setModalType] = useState<ModalOptions>(ModalOptions.NONE)
    const [deletionResult, setDeletionResult] = useState<OperationStatus>({ operationErrors: [], operationSuccessful: [] })
    const [isVariantLinked, setIsVariantLinked] = useState<boolean>(false)

    const updateData = () => {
        setLoaded(false)
        setDeletionResult({ operationErrors: [], operationSuccessful: [] })
        setSelectedKeys([])
        api.getProducts().then(response => {
            const products = response.data
            setFilteredProducts(products)
            setProducts(products)
        }).finally(() => setLoaded(true))
    }

    useEffect(() => {
        updateData()
    }, [])

    const handleSortChange = (sortedCustomers: Product[]) => {
        setFilteredProducts(sortedCustomers)
    }

    const handleAddProduct = () => {
        navigate('detail', { state: { selectedProduct: undefined } })
    }
    const handleRowClick = (selectedProduct: Product) => {
        const params = {
            selectedProduct: selectedProduct
        }
        navigate('detail', { state: params })
    }

    const handleDelete = () => {
        setIsDeleting(true)
        selectedKeys.forEach(key => {
            const productPk = Number(key.valueOf())
            const product = products.find(product => product.pk === productPk)!
            if (product.variantsCount > 0) {
                setIsDeleting(false)
                notification.error({
                    message: t('error'),
                    description: (<Trans> {t('productWithVariants', { name: product.title })}</Trans>),
                    duration: null,
                })
            } else {
                api.deleteProduct(productPk).then(response => {
                    setDeletionResult(prevState => (
                        {
                            operationErrors: prevState.operationErrors, operationSuccessful: [...prevState.operationSuccessful, productPk]
                        })
                    )
                }).catch((error: AxiosError) => {
                    notification.error({
                        message: t('error'),
                        description: `${t('somethingWentWrong')} ${error.message}`,
                    })
                    deletionResult.operationErrors.push({ pk: productPk, error: error.name })
                }).finally(() => {
                    if (deletionResult.operationErrors.length > 0) {
                        setModalType(ModalOptions.ERROR)
                        setSelectedKeys(deletionResult.operationErrors.map(errors => { return errors.pk }))
                    } else {
                        setModalType(ModalOptions.SUCCESSFUL)
                    }
                    setIsDeleting(false)
                })
            }
        })
        setModalType(ModalOptions.NONE)
    }

    const handleRowSelection = (keys: Key[]) => {
        setSelectedKeys(keys)
    }

    useEffect(() => {
        if (modalType === ModalOptions.NONE) {
            updateData()
        }
    }, [modalType])
    return (
        <div className={styles.container}>
            <div className={styles.titleRowContainer}>
                <TitleWithSubtitle title={t('products')} items={products.length} itemName={t('products')} />
                <div className={styles.buttonsContainer}>
                    <Button
                        onClick={() => setModalType(ModalOptions.DELETE)}
                        className={`blackFillPositiveButton ${styles.buttonCommons} ${styles.buttonDisabled}`}
                        disabled={selectedKeys.length === 0}>
                        {t('deleteSelected')}
                    </Button>
                    <Button
                        onClick={handleAddProduct}
                        className={`yellowFillPositiveButton ${styles.buttonCommons} ${styles.addProductButton}`}
                        icon={<AddProductIcon />}>
                        {t('addProduct')}
                    </Button>
                </div>
            </div>
            <Search
                placeholder={t('searchProducts')}
                className={styles.productSearch}
                size='large'
                allowClear />
            <div>
                <Row className={styles.productsContainer}>
                    <Col span={24}>
                        <TableView
                            selectedKeys={selectedKeys}
                            onRowSelect={handleRowSelection}
                            onRowClick={handleRowClick}
                            onSortChange={handleSortChange}
                            loaded={loaded}
                            data={filteredProducts} />
                    </Col>
                </Row>
            </div>
            <Modal
                closable={false}
                className={'modal'}
                open={isVariantLinked}
                footer={
                    <>
                        <Button
                            className={'blackFillPositiveButton'}
                            onClick={() => setIsVariantLinked(false)}>
                            OK
                        </Button>
                    </>
                }>
                <DangerIcon />
                <p>{t('somethingWentWrong')}</p>


            </Modal>
            <DeleteModalManager
                modalType={modalType}
                isDeleting={isDeleting}
                modalText={t('deleteProductQuestion', { count: selectedKeys.length })}
                modalResultText={t('deleteProductResult', { count: deletionResult.operationSuccessful.length })}
                onDelete={handleDelete}
                onChange={(value) => setModalType(value)}
            ></DeleteModalManager>
        </div >
    )
}

export default Products
