import { Form, Input, Select } from 'antd'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../app/store'
import { ReactComponent as LocationIcon } from '../../../../../assets/locationIcon.svg'
import { InventoryVariant, Location } from '../../../../../service/api'
import styles from './StockMoveModalForm.module.css'
interface StockMoveModalProps {
    form: any
    variant: InventoryVariant
    onFinish: (values: StockMoveFormValues) => void
}
export interface StockMoveFormValues {
    batchPk: number
    intervalStarting: number
    intervalEnding: number
    fromLocation: Location
    toLocation: Location | undefined
}

const MAX_VALUE = 2147483647
export const StockMoveModalForm = ({ onFinish, form, variant }: StockMoveModalProps): ReactElement => {
    const [t] = useTranslation('translations')
    const [selectedLocation, setSelectedLocation] = useState<Location | undefined>(undefined)
    const inventoryState = useSelector((state: RootState) => state.inventoryState)
    const initialValues: StockMoveFormValues = {
        batchPk: inventoryState.batch!.pk,
        intervalStarting: inventoryState.batch!.batchStart,
        intervalEnding: inventoryState.batch!.batchEnd,
        fromLocation: inventoryState.warehouse!,
        toLocation: undefined
    }
    return (
        <Form
            form={form}
            initialValues={initialValues}
            className={styles.form}
            layout='vertical'
            onFinish={(values: StockMoveFormValues) => {
                values.batchPk = inventoryState.batch!.pk
                values.toLocation = selectedLocation
                onFinish(values)
            }}
            requiredMark={false}>
            <div className={styles.modalHeader}>
                <Form.Item
                    label={t('batchIntervalStarting')}
                    name={'intervalStarting'}
                    rules={[
                        {
                            required: true,
                            message: ''
                        },
                        () => ({
                            validator(_, value) {
                                if (value <= MAX_VALUE) {
                                    return Promise.resolve()
                                } else if (value === undefined) {
                                    return Promise.reject()
                                }
                                return Promise.reject(new Error(t('maxValueReached')))
                            },
                        }),
                    ]}>
                    <Input disabled={true} type='number' placeholder='000000000009919900' />
                </Form.Item>
                <Form.Item
                    label={t('batchIntervalEnding')}
                    name={'intervalEnding'}
                    rules={[
                        {
                            required: true,
                            message: ''
                        },
                        () => ({
                            validator(_, value) {
                                if (value <= MAX_VALUE) {
                                    return Promise.resolve()
                                } else if (value === undefined) {
                                    return Promise.reject()
                                }
                                return Promise.reject(new Error(t('maxValueReached')))
                            },
                        }),
                    ]}>
                    <Input disabled={true} type='number' placeholder='000000000009929900' />
                </Form.Item>
            </div>
            <div className={styles.modalFooter}>
                <Form.Item
                    label={t('fromLocation')}
                    name={'fromLocation'}
                    rules={[
                        {
                            required: true,
                            message: ''
                        }
                    ]}>
                    <div className={styles.fromLocation}>
                        <LocationIcon /> <div className={styles.locationText}>{inventoryState.warehouse?.name}</div>
                    </div>
                </Form.Item>
                <Form.Item
                    label={t('toLocation')}
                    name='toLocation'
                    rules={[
                        {
                            required: true,
                            message: ''
                        }
                    ]}>
                    <Select
                        onSelect={(value, option) => {
                            setSelectedLocation(variant.inventoryLocations.find(loc => loc.pk === option.key))
                        }}
                        className={styles.locationSelector}
                        options={variant.inventoryLocations.
                            filter(location => location !== inventoryState.warehouse).
                            map(location => {
                                return {
                                    value: location.name,
                                    key: location.pk!
                                }
                            })}>
                    </Select>
                </Form.Item>
            </div>
        </Form>
    )
}