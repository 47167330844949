import { Layout } from 'antd'
import showtimeCrmLogo from '../../../assets/showtime_crm.svg'
import styles from './Sider.module.css'

export const Sider = (props: { menu: JSX.Element }) => {
    return (
        <div className={styles.menu}>
            <div className={styles.menuContentContainer}>
                <Layout.Sider
                    className={styles.sider}
                    width={220}
                    breakpoint={'lg'}
                    collapsedWidth={0}
                    trigger={null}>
                    <img src={showtimeCrmLogo} className={styles.menuLogo} />
                    {props.menu}
                </Layout.Sider >
            </div>
        </div>
    )
}
