import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/it'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setEndDate, setSelectedPresetValue, setStartDate } from '../../app/Reducers/DashboardSlice'
import { RootState } from '../../app/store'
import { ReactComponent as DateIcon } from '../../assets/datePickerImage.svg'
import { NO_SELECTION_VALUE } from '../../pages/dashboard/datePreset/DatePresetBar'
import './dateRangePicker.css'
const { RangePicker } = DatePicker
interface PropsType {
    dateRangeChanged?: (dates?: [dayjs.Dayjs, dayjs.Dayjs]) => void
    disabled: boolean
}

const DateRangePicker = (props: PropsType) => {
    const dispatch = useDispatch()
    const dateState = useSelector((state: RootState) => state.dashboardState)
    const [startLocalDate, setStartLocalDate] = useState<dayjs.Dayjs>(dateState.startDate)
    const [endLocalDate, setEndLocalDate] = useState<dayjs.Dayjs>(dateState.endDate)
    const [isOpen, setIsOpen] = useState<boolean>(false)

    useEffect(() => {
        setStartLocalDate(dateState.startDate)
        setEndLocalDate(dateState.endDate)
    }, [dateState.endDate, dateState.startDate])

    const handleIntervalChanged = (rangeDates: [dayjs.Dayjs, dayjs.Dayjs]) => {
        if (rangeDates) {
            if (rangeDates[0]) {
                dispatch(setStartDate(rangeDates[0].hour(0).minute(0).second(0).millisecond(0)))
            }
            if (rangeDates[1]) {
                dispatch(setEndDate(rangeDates[1].hour(23).minute(59).second(59).millisecond(999)))
            }
            setStartLocalDate(rangeDates[0].hour(0).minute(0).second(0).millisecond(0))
            setEndLocalDate(rangeDates[1].hour(23).minute(59).second(59).millisecond(999))
            dispatch(setSelectedPresetValue(NO_SELECTION_VALUE))
        }
    }

    useEffect(() => {
        if (!isOpen && (startLocalDate !== dateState.startDate || endLocalDate !== dateState.endDate)) {
            handleIntervalChanged([startLocalDate, endLocalDate])
        }
    }, [isOpen, startLocalDate, endLocalDate])

    return (
        <div>
            <RangePicker
                suffixIcon={<DateIcon />}
                format={'DD MMM YYYY'}
                mode={['date', 'date']}
                value={[startLocalDate, endLocalDate]}
                allowClear={false}
                disabled={props.disabled}
                onCalendarChange={dates => {
                    console.log(dates)
                    const rangeDates = dates as [dayjs.Dayjs, dayjs.Dayjs]
                    setStartLocalDate(rangeDates[0].hour(0).minute(0).second(0).millisecond(0))
                    setEndLocalDate(rangeDates[1].hour(23).minute(59).second(59).millisecond(999))
                }}
                onOpenChange={open => {
                    setIsOpen(open)
                }}
            />
        </div>
    )
}

export default DateRangePicker
