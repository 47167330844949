import { Button } from 'antd'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as EditIcon } from '../../../../assets/edit.svg'
import styles from './EditCard.module.css'

interface EditCardProps {
    sku: string,
    price: number
    onEdit: () => void
}
export const EditCard = ({ sku, price, onEdit }: EditCardProps): ReactElement => {
    const [t] = useTranslation('translations')
    return (
        <>
            <div className={styles.editCardContainer}>
                <div className={styles.identifierContainer}>
                    <div className={styles.title}>{t('SKU')}</div>
                    <div className={styles.item}>{sku}</div>
                </div>
                <div className={styles.priceContainer}>
                    <div className={styles.title}>{t('price')}</div>
                    <div className={styles.item}>{price}</div>
                </div>
                <Button
                    onClick={onEdit}
                    className={styles.editButton}
                    type={'text'}
                    icon={<EditIcon />} />
            </div>
        </>
    )
}