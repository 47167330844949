import { Button, Modal } from 'antd'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CheckMarkIcon } from '../../../assets/checkMarkIcon.svg'
import { ReactComponent as AlertIcon } from '../../../assets/danger.svg'
import { TicketResultItem } from '../../../service/model'
import styles from './TicketCancelModal.module.css'
import './ticketingModal.css'
export enum CancelTicketErrorType {
    SHOW_NOT_FOUND,
    CODE_NOT_FOUND,
    SERVER_NOT_RESPONDING,
    TICKETS_NOT_FOUND,
    NONE
}
interface TicketIssueModalProps {
    open: boolean
    errorType: CancelTicketErrorType
    showTitle: string
    showDate: string
    tickets: TicketResultItem
    handleConfirmClick: () => void
}

const TicketCancelModal = (props: TicketIssueModalProps): ReactElement => {
    const [t] = useTranslation('translations')
    const [selectedModal, setSelectedModal] = useState<JSX.Element>()
    const modalIcon = props.errorType !== CancelTicketErrorType.NONE ? <AlertIcon /> : <CheckMarkIcon />
    const createOkModal = () => {
        return (
            <>
                <p className={styles.modalDate}>{t('ticketsCanceled')}</p>
            </>
        )
    }

    const createTicketsNotFoundModal = () => {
        return (
            <>
                <p className={styles.modalTitle}>{t('noTicketsFoundTitle')}</p>
                <p className={styles.modalDate}>{t('noTicketsFoundText')}</p>
            </>
        )
    }
    const createFailedCodesModal = () => {
        return (
            <>
                <p className={styles.modalTitle}>{t('codeNotCancelled')}</p>
                <ul className={styles.listContainer}>
                    {props.tickets.failedCodes.map((failedCode, index) => (
                        <li key={index}>{failedCode.code}
                            <p className={styles.errorMessage}> {failedCode.error}</p>
                        </li>
                    ))}
                </ul>
            </>
        )
    }
    const createShowNotFoundModal = () => {
        return (
            <>
                <p className={styles.modalTitle}>{t('showNotFound')}</p>
                <p className={styles.modalDate}>{t('showNotFoundCancelText')}</p>
            </>
        )
    }
    const createServerNotRespondingModal = () => {
        return (
            <>
                <p className={styles.modalTitle}>{t('serverNotRespondingTitle')}</p>
                <p className={styles.modalDate}>{t('ticketsNotCanceled')}</p>
            </>
        )
    }
    useEffect(() => {
        switch (props.errorType) {
        case CancelTicketErrorType.SHOW_NOT_FOUND:
            setSelectedModal(createShowNotFoundModal)
            break
        case CancelTicketErrorType.CODE_NOT_FOUND:
            setSelectedModal(createFailedCodesModal)
            break
        case CancelTicketErrorType.SERVER_NOT_RESPONDING:
            setSelectedModal(createServerNotRespondingModal)
            break
        case CancelTicketErrorType.TICKETS_NOT_FOUND:
            setSelectedModal(createTicketsNotFoundModal)
            break
        default: setSelectedModal(createOkModal)
        }
    }, [])

    return (
        <div>
            <Modal
                className={'ticketingModal ticketingModalPrimaryButton'}
                open={props.open}
                closable={false}
                footer={
                    <Button onClick={props.handleConfirmClick}>
                        OK
                    </Button>}>
                {modalIcon}{selectedModal}
            </Modal>
        </div>
    )
}

export default TicketCancelModal