import { Button, Form, Modal } from 'antd'
import { AxiosError } from 'axios'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { sha512 } from 'sha512-crypt-ts'
import { RootState } from '../../../../app/store'
import { ReactComponent as OkIcon } from '../../../../assets/checkMarkIcon.svg'
import { ReactComponent as AlertIcon } from '../../../../assets/danger.svg'
import '../../../../common/commonCss/buttons.css'
import '../../../../common/commonCss/modal.css'
import BackButton from '../../../../common/components/commonUI/BackButton'
import TitleWithSubtitle from '../../../../common/components/commonUI/TitleWithSubtitle'
import { User, UsersApiFactory } from '../../../../service/api'
import { ErrorResponse } from '../../../../service/model'
import { UserError, UserRole } from '../../AdminUtils'
import UserDetailForm, { PASSWORD_PLACEHOLDER, UserFormValue } from '../UserDetailForm/UserDetailForm'
import styles from './UserDetail.module.css'

interface UserDetailParams {
    selectedUser: User
}
const UserDetail = (): ReactElement => {
    const api = UsersApiFactory()
    const [t] = useTranslation('translations')
    const location = useLocation()
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [isModifying, setIsModifying] = useState<boolean>(false)
    const [isDeleted, setIsDeleted] = useState<boolean>(false)
    const [isSaved, setIsSaved] = useState<boolean>(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
    const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')

    const selectedUser: User | undefined = (location.state as UserDetailParams).selectedUser
    const currentUserState = useSelector((state: RootState) => state.currentUserState)

    const handleDeleteUser = () => {
        api.deleteUser(selectedUser.pk!).then(() => {
            setIsDeleted(true)
        }).catch(error => {
            switch (error.name) {
            case UserError.ERROR_404:
                setErrorMessage(t('userNotFound'))
                break
            case UserError.ERROR_403: {
                if (currentUserState.user.role !== UserRole.ADMIN) {
                    setErrorMessage(t('userNotAdmin'))
                }
                setErrorMessage(t('userNotInOrganization'))
                break
            }
            default: setErrorMessage(t('somethingWentWrong'))
            }
        })
    }
    const handleFinish = (values: UserFormValue) => {
        api.saveUser(selectedUser.pk, {
            username: values.username,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            role: values.role,
            organizations: currentUserState.user.organizations !== undefined ?
                currentUserState.user.organizations.filter(o => {
                    return values.organizations.includes(o.pk.toString())
                })
                : [],
            company: values.company,
            password: values.password !== PASSWORD_PLACEHOLDER ? sha512.hex(values.password) : null,
            notification: values.notification,
            pk: selectedUser.pk,
            timestamp: selectedUser.timestamp,
            enabled: selectedUser.enabled,
            tenantId: selectedUser.tenantId
        }).then(() => setIsSaved(true)).catch((error: AxiosError) => {
            switch (error.response?.status) {
            case UserError.ERROR_409:
                setErrorMessage(t('userConflict'))
                break
            case UserError.ERROR_404:
                setErrorMessage(t('userNotFound'))
                break
            case UserError.ERROR_403: {
                if ((error.response!.data as ErrorResponse).title) {
                    setErrorMessage(t('userConflict'))
                } else if (currentUserState.user.role !== UserRole.ADMIN) {
                    setErrorMessage(t('userNotAdmin'))
                } else {
                    setErrorMessage(t('userNotInOrganization'))
                }
                break
            }
            default: setErrorMessage(t('somethingWentWrong'))
            }
            setIsError(true)
        })
    }
    const handleRestoreChanges = () => {
        form.resetFields()
        setIsCancelModalOpen(false)
        setIsModifying(false)
    }
    const handleModifyUser = () => {
        setIsModifying(prevState => !prevState)
    }
    return (
        <div className={styles.container}>
            <div className={styles.titleRowContainer}>
                <TitleWithSubtitle
                    title={`${selectedUser.firstName} ${selectedUser.lastName}`}
                    itemName={t('account')} />
                <div className={styles.buttonsContainer} >
                    {!isModifying ? <div>
                        <Button
                            className={`blackFillPositiveButton ${styles.deleteButton}`}
                            onClick={() => setIsDeleteModalOpen(true)}>{t('delete')}
                        </Button>
                        <Button
                            className='yellowFillPositiveButton'
                            onClick={handleModifyUser}>
                            {t('edit')}
                        </Button>
                    </div>
                        :
                        <div>
                            <Button
                                className={`blackFillPositiveButton ${styles.deleteButton}`}
                                onClick={() => setIsCancelModalOpen(true)}>{t('cancel')}
                            </Button>
                            <Button
                                className='yellowFillPositiveButton'
                                onClick={() => form.submit()}>
                                {t('saveEditing')}
                            </Button>
                        </div>
                    }
                </div >
            </div >
            <div className={styles.backButtonContainer}>
                <BackButton />
            </div>
            <div className={styles.userForm}>
                <UserDetailForm
                    organizations={currentUserState.user.organizations}
                    form={form}
                    disabled={!isModifying}
                    onFinish={handleFinish}
                    selectedUser={selectedUser} />
            </div >
            <Modal
                closable={false}
                className={'modal'}
                open={isDeleteModalOpen}
                footer={
                    <>
                        <Button className='yellowOutlinedCancelButton' onClick={() => setIsDeleteModalOpen(false)}>
                            {t('cancel')}
                        </Button>
                        <Button className={'blackFillPositiveButton'} onClick={handleDeleteUser}>
                            {t('delete')}
                        </Button>
                    </>
                }>
                <AlertIcon></AlertIcon>
                <p>{t('deleteUserQuestion')}</p>
            </Modal>
            <Modal
                closable={false}
                className={'modal'}
                open={isDeleted}
                footer={
                    <>
                        <Button className={'blackFillPositiveButton'} onClick={() => navigate(-1)}>
                            OK
                        </Button>
                    </>
                }>
                <AlertIcon></AlertIcon>
                <p>{t('userDeleted')}</p>
            </Modal>
            <Modal
                closable={false}
                className={'modal'}
                open={isSaved}
                footer={
                    <>
                        <Button className={'blackFillPositiveButton'} onClick={() => navigate(-1)}>
                            OK
                        </Button>
                    </>
                }>
                <OkIcon></OkIcon>
                <p>{t('userSaved')}</p>
            </Modal>
            <Modal
                closable={false}
                className={'modal'}
                open={isCancelModalOpen}
                footer={
                    <>
                        <Button className='yellowOutlinedCancelButton' onClick={handleRestoreChanges}>
                            {t('cancelModalYes')}
                        </Button>
                        <Button className={'blackFillPositiveButton'} onClick={() => setIsCancelModalOpen(false)}>
                            {t('keepEditing')}
                        </Button>
                    </>
                }>
                <AlertIcon></AlertIcon>
                <p>{t('cancelEditingQuestion')}</p>
            </Modal>
            <Modal
                closable={false}
                className={'modal'}
                open={isError}
                footer={
                    <>
                        <Button className={'blackFillPositiveButton'} onClick={() => setIsError(false)}>
                            OK
                        </Button>
                    </>
                }>
                <AlertIcon></AlertIcon>
                <p>{errorMessage}</p>
            </Modal>
        </div >
    )
}

export default UserDetail