import Icon from '@ant-design/icons'
import { Empty, Table } from 'antd'
import { TableRowSelection } from 'antd/es/table/interface'
import type { ColumnsType } from 'antd/lib/table'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as OrganizationDeafultIcon } from '../../../assets/OrganizationsDefaultIcon.svg'
import { ReactComponent as NoDataIcon } from '../../../assets/cloud-cross.svg'
import { Colors } from '../../../common/Colors'
import '../../../common/commonCss/tableView.css'
import CustomSkeleton from '../../../common/components/CustomSkeleton'
import { Organization } from '../../../service/api'

interface PropsType {
    loaded: boolean
    data: Organization[]
    onSelect: (newSelectedRowKeys: React.Key[]) => void
    onRowClick: (seletedItem: Organization) => void
}

export default function OrganizationsTable(props: PropsType) {

    const [t] = useTranslation('translations')
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
        props.onSelect(newSelectedRowKeys)
    }
    const rowSelection: TableRowSelection<Organization> = {
        selectedRowKeys,
        onChange: onSelectChange,
    }
    const columns: ColumnsType<Organization> = [
        {
            title: t('organization'),
            dataIndex: 'name',
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name)
            },
            render: (productName: string, record: Organization) => (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon
                        component={OrganizationDeafultIcon}
                        style={{ fontSize: '28px', marginRight: 14 }} />
                    {productName}
                </span>
            )
        },
        {
            title: t('group'),
            dataIndex: 'groupName',
            sorter: {
                compare: (a, b) => {
                    if (a.groupName && b.groupName) {
                        return a.groupName.localeCompare(b.groupName)
                    } else if (a.groupName) {
                        return -1
                    } else if (b.groupName) {
                        return 1
                    } else {
                        return 0
                    }
                }
            }
        }
    ]
    return (
        <div >
            {!props.loaded ?
                <div style={{ paddingBottom: '160px' }}>
                    <CustomSkeleton height={300} />
                </div>
                :
                <>
                    <Table
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    props.onRowClick(record)
                                }
                            }
                        }}
                        rowKey={(record) => record.pk!}
                        rowSelection={rowSelection}
                        dataSource={props.data}
                        columns={columns}
                        className={'tableView'}
                        showSorterTooltip={false}
                        pagination={{
                            position: ['bottomCenter'],
                            hideOnSinglePage: props.data.length <= 10,
                            showSizeChanger: true,
                            style: {
                                marginTop: '45px'
                            },
                            responsive: true,
                            showTitle: false
                        }}
                    />
                    {props.data.length === 0 &&
                        <Empty
                            image={<Icon component={NoDataIcon} />}
                            imageStyle={{ fontSize: '60px', marginTop: '50px', marginBottom: '-8px' }}
                            style={{ marginTop: '50px', paddingBottom: '180px' }}
                            description={(
                                <span style={{ color: Colors.black }}>{t('noData')}</span>
                            )} />
                    }
                </>
            }
        </div>
    )
}

