import { Button, List } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import openDetailIcon from '../../../../assets/expand.svg'
import ChartTooltip from '../../../../common/components/charts/ChartTooltip'
import ExportCsv from '../../../../common/components/charts/ExportCsv'
import CustomSkeleton from '../../../../common/components/CustomSkeleton'
import NoData from '../../../../common/components/NoData'
import i18n from '../../../../i18n'
import { DashboardApiFactory } from '../../../../service/api'
import NoSelection from '../../NoSelection'
import classStyle from '../dashboardGraphs.module.css'
import styles from './listChart.module.css'
import ListItem from './ListItemComponent/ListItem'

interface PropsType {
    title: string
    dataSource: string
    tooltip: string
    onDetailClick: (title: string, tooltip: string, data: any[], columnNames: string[], columnTypes: string[], dataSource: string) => void
    onExportClick: (title: string, data: any[]) => void
}

const ListChart = (props: PropsType) => {
    const api = DashboardApiFactory()
    const [t] = useTranslation('translations')
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [listChartData, setListChartData] = useState<any[]>([])
    const [columnLabels, setColumnLabels] = useState<string[]>([])
    const [columnTypes, setColumnTypes] = useState<string[]>([])
    const dashboardState = useSelector((state: RootState) => state.dashboardState)
    const error = dashboardState.selectedItemsIds.length === 0 ? true : false
    const title: string = t(props.title as unknown as TemplateStringsArray)
    const tooltip: string = t(props.tooltip as unknown as TemplateStringsArray)
    const refresh = () => {
        if (!error) {
            setIsLoading(true)
            const start = dashboardState.startDate.clone()
            const end = dashboardState.endDate.clone()
            api.getChartData(props.dataSource, {
                pks: dashboardState.selectedItemsIds,
                startDate: start.valueOf(),
                endDate: end.valueOf()
            }).then(response => {
                const rawData = response.data
                const labels: string[] = []
                rawData.labels.forEach((label) => {
                    labels.push(i18n.t('translations:' + label))
                })
                setColumnLabels(labels)
                setColumnTypes(rawData.types)
                setListChartData(rawData.data.map((item) => {
                    const result = {}
                    item.map((value, index) => ({
                        [labels[index]]: value
                    })).forEach(object => {
                        if (object[i18n.t('translations:status')] !== undefined) {
                            object[i18n.t('translations:status')] = i18n.t('translations:' + object[i18n.t('translations:status')])
                        } Object.assign(result, object)
                    })
                    return result
                }))
                setIsLoading(false)
            })
        }
    }
    useEffect(() => {
        refresh()
    }, [dashboardState.selectedItemsIds, dashboardState.startDate, dashboardState.endDate])

    const handleDetailClick = () => {
        props.onDetailClick(title, tooltip, listChartData, columnLabels, columnTypes, props.dataSource)
    }
    return (
        <div key={props.title}>
            {isLoading ?
                <CustomSkeleton height={280} />
                :
                <div className={classStyle.container}>
                    <div className={classStyle.titleContainer}>
                        <h1 className={classStyle.title}>
                            {title}
                            <span className={styles.tooltipContainer}>
                                <ChartTooltip tooltipText={tooltip} />
                            </span>
                        </h1>
                        {(!error && listChartData.length > 0) &&
                            <div className={classStyle.more}>
                                <ExportCsv filename={title} data={listChartData} isImage={true} />
                                <Button
                                    className={classStyle.graphIconButton}
                                    icon={<img src={openDetailIcon} />}
                                    onClick={handleDetailClick} />
                            </div>
                        }
                    </div>

                    <div className={classStyle.contentContainer}>
                        {
                            !error ? (
                                listChartData.length > 0 ? (
                                    <div className={styles.listContainer}>
                                        <List
                                            className={styles.listContainer}
                                            itemLayout="horizontal"
                                            dataSource={listChartData.slice(0, 4)}
                                            renderItem={item => (
                                                <ListItem data={item} labels={columnLabels} />
                                            )}
                                        />
                                    </div>
                                ) : (
                                    <NoData />
                                )
                            ) : (
                                <NoSelection
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                        width: '100%',
                                        margin: '0 auto',
                                        paddingBottom: '30px'
                                    }}
                                    imageStyle={{ width: '40px', height: '40px', margin: 'auto' }}
                                />
                            )
                        }
                    </div>
                </div>
            }
        </div>
    )
}
export default ListChart
