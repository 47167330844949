import { Button, Modal } from 'antd'
import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as AlertIcon } from '../../../assets/danger.svg'
import '../../../common/commonCss/modal.css'
export enum ModalType {
    CANCEL_MODAL,
    ERROR_MODAL,
    DELETE_MODAL,
    NONE
}
interface ModalManagerProps {
    type: ModalType
    open: boolean
    onCancelConfirm: () => void
    onDeleteConfirm?: () => void
    onModalClose: () => void
}
export const ModalManager = ({ open, type, onCancelConfirm, onDeleteConfirm, onModalClose }: ModalManagerProps) => {
    const [t] = useTranslation('translations')
    const [currentModal, setCurrentModal] = useState<JSX.Element>()
    const [footer, setFooter] = useState<ReactNode>()
    const [openModal, setOpenModal] = useState<boolean>(false)
    const navigate = useNavigate()
    const createCancelModal = () => {
        setFooter(
            <>
                <Button
                    className='yellowOutlinedCancelButton'
                    onClick={() => onCancelConfirm()}>
                    {t('yesImSure')}
                </Button>
                <Button
                    className={'blackFillPositiveButton'}
                    onClick={onModalClose}>
                    {t('keepEditing')}
                </Button>
            </>)
        setCurrentModal(<p>{t('cancelEditingQuestion')}</p>)
    }
    const createDeleteModal = () => {
        setFooter(
            <>
                <Button
                    className='yellowOutlinedCancelButton'
                    onClick={onModalClose}>
                    {t('cancel')}
                </Button>
                <Button
                    className={'blackFillPositiveButton'}
                    onClick={() => onDeleteConfirm!()}>
                    {t('delete')}
                </Button>
            </>)
        setCurrentModal(<p>{t('modalDeleteQuestion')}</p>)
    }

    const createCompleteDeleteModal = () => {
        setFooter(
            <Button
                onClick={() => navigate(-1)}
                className='blackFillPositiveButton'>
                {'OK'}
            </Button>)
        setCurrentModal(<p>{t('organizationDeleted')}</p>)
    }

    const createErrorModal = () => {
        setFooter(
            <Button
                onClick={() => onModalClose()}
                className='blackFillPositiveButton'>
                {'OK'}
            </Button>)
        setCurrentModal(<p>{t('somethingWentWrong')}</p>)
    }

    useEffect(() => {
        if (type !== ModalType.NONE) {
            setOpenModal(true)
        }
        switch (type) {
        case ModalType.ERROR_MODAL:
            createErrorModal()
            break
        case ModalType.CANCEL_MODAL:
            createCancelModal()
            break
        case ModalType.DELETE_MODAL:
            if (onDeleteConfirm !== undefined) {
                createDeleteModal()
            }
            break
        case ModalType.NONE:
            setOpenModal(false)
        }

    }, [type, open])

    return (
        <div>
            <Modal
                className='modal'
                open={openModal}
                closable={false}
                footer={[footer]}
            >
                <>
                    <AlertIcon />
                    {currentModal}
                </>
            </Modal>
        </div>
    )
}