import { PayloadAction, combineReducers, configureStore } from '@reduxjs/toolkit'
import ecommerceReducer from './Reducers/CardSlice'
import communityReducer from './Reducers/CommunitySlice'
import dashboardReducer from './Reducers/DashboardSlice'
import inventoryReducer from './Reducers/InventorySlice'
import topbarReducer from './Reducers/TopbarSlice'
import userInfoReducer from './Reducers/UserInfoSlice'
const combinedReducer = combineReducers({
    community: communityReducer,
    dashboardState: dashboardReducer,
    currentUserState: userInfoReducer,
    inventoryState: inventoryReducer,
    ecommerceState: ecommerceReducer,
    topbarState: topbarReducer
})

const rootReducer = (state: any, action: PayloadAction) => {
    if (action.type === 'userInfo/logoutUser') {
        state = undefined
    }
    return combinedReducer(state, action)
}

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>