import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import backIcon from '../../../assets/back_active.svg'
import styles from './BackButton.module.css'
const BackButton = () => {
    const [t] = useTranslation('translations')
    const navigate = useNavigate()
    return (
        <Button className={styles.backButtonContainer} onClick={() => navigate(-1)}>
            <img src={backIcon} className={styles.backIcon} />
            <span className={styles.backLabel}>{t('back')}</span>
        </Button>
    )
}

export default BackButton