import Icon from '@ant-design/icons'
import { Empty, Table } from 'antd'
import { FilterValue, SorterResult, TableRowSelection } from 'antd/es/table/interface'
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import dayjs from 'dayjs'
import { Key, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as NoDataIcon } from '../../../../assets/cloud-cross.svg'
import { ReactComponent as ContactAppIcon } from '../../../../assets/contact_app_empty.svg'
import { ReactComponent as ContactPicIcon } from '../../../../assets/contact_pic_empty.svg'
import { Colors } from '../../../../common/Colors'
import '../../../../common/commonCss/tableView.css'
import CustomSkeleton from '../../../../common/components/CustomSkeleton'
import { formatDate, getFullName, isAppCustomer } from '../../../../common/utils'
import { CustomerDetail } from '../../../../service/api'
import styles from './TableView.module.css'

interface PropsType {
    loaded: boolean
    data: CustomerDetail[]
    onSortChange: (sortedItems: CustomerDetail[]) => void
    onSelect: (selectedItems: Key[]) => void
    selectedKeys: React.Key[]
    onRowClick: (clickedRow: CustomerDetail) => void
}

export default function TableView(props: PropsType) {
    const [t, i18n] = useTranslation('translations')
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(props.selectedKeys)

    useEffect(() => {
        setSelectedRowKeys(props.selectedKeys)
    }, [props.selectedKeys])
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
        props.onSelect(newSelectedRowKeys)
    }
    const rowSelection: TableRowSelection<CustomerDetail> = {
        selectedRowKeys,
        onChange: onSelectChange,
    }
    const columns: ColumnsType<CustomerDetail> = [
        {
            title: t('name'),
            dataIndex: 'firstName',
            sorter: {
                compare: (a, b) => {
                    if (a.lastName && b.lastName) {
                        return a.lastName.localeCompare(b.lastName)
                    }
                    if (a.lastName) {
                        return -1
                    }
                    if (b.lastName) {
                        return 1
                    }
                    return 0
                }
            },
            render: (firstName: string, record: CustomerDetail) => (
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {record.pictureUrl ? (
                        <img
                            src={new URL('http://' + record.pictureUrl).toString()}
                            style={{ width: 28, height: 28, marginRight: 14, borderRadius: 14 }} />
                    ) : (
                        <Icon
                            component={isAppCustomer(record.type) ? ContactAppIcon : ContactPicIcon}
                            style={{ fontSize: '28px', marginRight: 14 }} />
                    )}
                    {getFullName({ firstName: record.firstName, lastName: record.lastName })}
                </span>
            )
        },
        {
            title: t('email'),
            dataIndex: 'email',
            sorter: {
                compare: (a, b) => a.email.localeCompare(b.email)
            }
        },
        {
            title: t('city'),
            dataIndex: 'city',
            sorter: {
                compare: (a, b) => (a.city ?? '').localeCompare(b.city ?? '')
            }
        },
        {
            title: t('registration'),
            dataIndex: 'registrationDate',
            defaultSortOrder: 'descend',
            sorter: {
                compare: (a, b) => (a.registrationDate ?? 0) - (b.registrationDate ?? 0)
            },
            render: (registrationDate?: number) => {
                const date = registrationDate ?? null
                return (
                    <span>{(date !== null) && formatDate(dayjs(date), 'DD/MM/YYYY')}</span>
                )
            }
        },
        {
            title: t('phone'),
            dataIndex: 'phone'
        }
    ]
    const handleSortChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<CustomerDetail>[] | SorterResult<CustomerDetail>,
        extra: { currentDataSource: CustomerDetail[] }) => {
        props.onSortChange(extra.currentDataSource)
    }
    return (
        <div className={styles.tableContainer}>
            {!props.loaded ?
                <div className={styles.skeletonContainer}>
                    <CustomSkeleton height={300} />
                </div>
                :
                <>
                    <Table
                        scroll={{ x: 400 }}
                        rowSelection={rowSelection}
                        rowKey={record => record.pk}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    props.onRowClick(record)
                                }
                            }
                        }}
                        onChange={handleSortChange}
                        dataSource={props.data}
                        columns={columns}
                        className={'tableView'}
                        showSorterTooltip={false}
                        pagination={{
                            locale: {
                                items_per_page: i18n.t('itemsPerPage'),
                            },
                            position: ['bottomCenter'],
                            hideOnSinglePage: props.data.length <= 10,
                            showSizeChanger: true,
                            style: {
                                marginTop: '45px'
                            },
                            responsive: true,
                            showTitle: false
                        }}
                    />
                    {props.data.length === 0 &&
                        <Empty
                            image={<Icon component={NoDataIcon} />}
                            imageStyle={{ fontSize: '60px', marginTop: '90px', marginBottom: '-8px' }}
                            style={{ marginTop: '100px', paddingBottom: '180px' }}
                            description={(
                                <span style={{ color: Colors.black }}>{t('noContacts')}</span>
                            )} />
                    }
                </>
            }
        </div>
    )
}
