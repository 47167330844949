import { Form, Input } from 'antd'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './StockInModalForm.module.css'

interface StockInModalProps {
    form: any
    onFinish: (values: StockInFormValues) => void
}
export interface StockInFormValues {
    intervalStarting: number
    intervalEnding: number
}

const MAX_VALUE = 2147483647
export const StockInModalForm = ({ onFinish, form }: StockInModalProps): ReactElement => {
    const [t] = useTranslation('translations')
    return (
        <Form
            form={form}
            className={styles.form}
            layout='vertical'
            onFinish={onFinish}
            requiredMark={false}>
            <Form.Item
                label={t('batchIntervalStarting')}
                name={'intervalStarting'}
                rules={[
                    {
                        required: true,
                        message: ''
                    },
                    () => ({
                        validator(_, value) {
                            if (value <= MAX_VALUE) {
                                return Promise.resolve()
                            } else if (value === undefined) {
                                return Promise.reject()
                            }
                            return Promise.reject(new Error(t('maxValueReached')))
                        },
                    }),
                ]}>
                <Input type='number' placeholder='000000000009919900' />
            </Form.Item>
            <Form.Item
                label={t('batchIntervalEnding')}
                name={'intervalEnding'}
                rules={[
                    {
                        required: true,
                        message: ''
                    },
                    () => ({
                        validator(_, value) {
                            if (value <= MAX_VALUE) {
                                return Promise.resolve()
                            } else if (value === undefined) {
                                return Promise.reject()
                            }
                            return Promise.reject(new Error(t('maxValueReached')))
                        },
                    }),
                ]}>
                <Input type='number' placeholder='000000000009929900' />
            </Form.Item>
        </Form>
    )
}