import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import DateRangePicker from '../../../common/components/DateRangePicker'
import TitleRefresh from '../../../common/components/commonUI/TitleRefresh'
import { useMobileMediaQuery } from '../../../hooks/useMobileMediaQuery'
import ItemSelector from '../ItemSelector'
import DatePresetBar from '../datePreset/DatePresetBar'
import CustomGridComponent from './CustomGridComponent'
import classStyles from './Dashboard.module.css'
import { DashboardConfiguration } from './DashboardModel'


const Dashboard = () => {
    const isMobile = useMobileMediaQuery()
    const [t] = useTranslation('translations')
    const dashboardState = useSelector((state: RootState) => state.dashboardState)
    const [dashboardConfig, setDashboardConfig] = useState<DashboardConfiguration>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [gridKey, setGridKey] = useState<number>(0)

    useEffect(() => {
        if (dashboardState.config !== undefined) {
            setDashboardConfig(dashboardState.config)
        }
    }, [dashboardState.config])

    const refresh = () => {
        setGridKey(key => key + 1)
    }

    const itemSelector = dashboardConfig &&
        <ItemSelector
            selectorConfig={dashboardConfig.filter}
            loading={isLoading}
            onLoading={(loading) => setIsLoading(loading)} />
    return (
        <>
            <div className={classStyles.container}>
                {isMobile &&
                    <div>
                        {itemSelector}
                    </div>
                }
                <div className={classStyles.titleContainer}>
                    <div className={classStyles.dashboardTitle}>
                        <TitleRefresh
                            title={t('dashboard')}
                            onClick={refresh}
                            loaded={!isLoading} />
                    </div>
                    <div>
                        <DateRangePicker disabled={isLoading} />
                    </div>
                </div>
                <div className={classStyles.itemContainer}>
                    {(!isMobile && dashboardConfig) &&
                        <div>
                            {itemSelector}
                        </div>
                    }
                    <div className={classStyles.datePresetContainer}>
                        <DatePresetBar disabled={isLoading} />
                    </div>
                </div>
                <div className={classStyles.grid}>
                    {dashboardConfig &&
                        <CustomGridComponent
                            loaded={!isLoading}
                            key={gridKey}
                            grid={dashboardConfig.grid}
                            tables={dashboardConfig.tables} />
                    }
                </div>
            </div>
        </>
    )
}
export default Dashboard
