import { Menu, Popover } from 'antd'
import { ItemType } from 'antd/lib/menu/interface'
import { TooltipPlacement } from 'antd/lib/tooltip'
import { useState } from 'react'
import './Popover.css'
import styles from './popupMenu.module.css'
interface PropsType {
    children?: React.ReactNode
    content: ItemType[]
    popupVisible?: boolean
    placement?: TooltipPlacement
}

const PopupMenu = (props: PropsType) => {
    const [popupVisible, setPopupVisible] = useState<boolean>(false)
    const content = (
        <Menu
            onClick={() => setPopupVisible(false)}
            mode={'vertical'}
            items={props.content}
            selectable={false}
        />
    )

    return (
        <Popover
            className={styles.popover}
            placement={props.placement ?? 'bottom'}
            content={content}
            trigger="click"
            open={popupVisible}
            onOpenChange={(visible: boolean) => setPopupVisible(visible)}>
            {props.children}
        </Popover>
    )
}

export default PopupMenu
