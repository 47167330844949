import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CustomerDetail } from '../../service/api'

interface CommunityState {
    customers: CustomerDetail[]
}

const initialState: CommunityState = {
    customers: []
}

export const communitySlice = createSlice({
    name: 'community',
    initialState,
    reducers: {
        setCustomers: (state, action: PayloadAction<CustomerDetail[]>) => {
            state.customers = action.payload
        }
    }
})

export const { setCustomers } = communitySlice.actions

export const selectCustomers = (state: CommunityState) => state.customers

export default communitySlice.reducer