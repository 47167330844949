import { Button, Flex, Menu, notification } from 'antd'
import { MenuProps } from 'antd/lib'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import '../../../../../common/commonCss/buttons.css'
import i18n from '../../../../../i18n'
import { CustomerDetail } from '../../../../../service/api'
import { ContactAction, ContactActionComponent } from './ContactActionsDetail/ContactActionComponent/ContactActionComponent'
import { ContactActionsLogComponent } from './ContactActionsLogComponent'
import styles from './ContactDetailFooter.module.css'
import { ContactDetailTable, ContactTableType } from './ContactDetailTable'

type MenuItem = Required<MenuProps>['items'][number]

interface ContactDetailFooterProps {
    selectedCustomer: CustomerDetail
}

export const getTranslationFromActionType = (action: ContactAction) => {
    switch (action) {
    case ContactAction.CALL:
        return i18n.t('loggedCall')
    case ContactAction.SMS:
        return i18n.t('loggedSms')
    case ContactAction.EMAIL:
        return i18n.t('loggedEmail')
    case ContactAction.NOTE:
        return i18n.t('loggedNote')
    case ContactAction.WHATSAPP:
        return i18n.t('loggedWhatsapp')
    }
}
export const ContactDetailFooter = (props: ContactDetailFooterProps): ReactElement => {
    const [t] = useTranslation('translations')
    const [activeType, setActiveType] = useState<ContactTableType>(ContactTableType.History)
    const [actionModalOpen, setActionModalOpen] = useState<{ open: boolean, action: ContactAction }>({ open: false, action: ContactAction.EMAIL })
    const navigate = useNavigate()
    const [key, setKey] = useState<number>(0)
    const items: MenuItem[] = [
        {
            key: 1,
            label: t('history'),
            onClick: () => setActiveType(ContactTableType.History)
        },
        {
            key: 2,
            label: t('feedbacks'),
            onClick: () => setActiveType(ContactTableType.Feedback)
        },
        {
            key: 3,
            label: t('devices'),
            onClick: () => setActiveType(ContactTableType.Devices)
        },
        {
            key: 4,
            label: t('tickets'),
            onClick: () => setActiveType(ContactTableType.Tickets)
        },
    ]

    const navigateToDetailPage = () => {
        switch (activeType) {
        case ContactTableType.Tickets:
            navigate('purchase', { state: props.selectedCustomer })
            break
        case ContactTableType.History:
            navigate('history', { state: props.selectedCustomer })
            break
        }
    }
    
    return (
        <div className={styles.contactActionsContainer}>
            <Flex vertical={true} className={styles.flex}>
                <ContactActionsLogComponent onClick={value => setActionModalOpen(value)} />
                <div className={styles.contactDetailTable}>
                    <div className={styles.menuContainer}>
                        <Menu
                            defaultSelectedKeys={['1']}
                            className={styles.contactMenu}
                            items={items} />
                    </div>
                    <div className={styles.tableContainer}>
                        <ContactDetailTable
                            key={key}
                            selectedCustomer={props.selectedCustomer}
                            activeType={activeType} />
                    </div>
                </div>
                <div className={styles.viewAllButton}>
                    {(activeType === ContactTableType.History || activeType === ContactTableType.Tickets) &&
                        <Button onClick={() => navigateToDetailPage()} className={'yellowFillPositiveButton'}>
                            {t('viewAll')}
                        </Button>
                    }
                </div>
            </Flex>
            <ContactActionComponent
                contactPk={props.selectedCustomer.pk}
                menu={actionModalOpen}
                onFinish={() => setKey(prevState => prevState + 1)}
                onClose={() => setActionModalOpen(prevState => { return { ...prevState, open: false } })} />
        </div>
    )
}