import { Button } from 'antd'
import Title from 'antd/es/typography/Title'
import refreshIcon from '../../../assets/refresh.svg'
import styles from './TitleRefresh.module.css'
interface PropsType {
    title: string,
    loaded: boolean,
    onClick: () => void
}

const TitleRefresh = (props: PropsType) => {
    return (
        <div className={styles.titleContainer}>
            <Title level={3} className={styles.title}>{props.title}</Title>
            <Button
                icon={<img src={refreshIcon} className={styles.refreshButton} />}
                className={styles.refreshButtonContainer}
                shape="circle"
                onClick={props.onClick}
                disabled={!props.loaded} />
        </div>
    )
}

export default TitleRefresh