import { Button, Form, Modal } from 'antd'
import { AxiosError } from 'axios'
import { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as CheckMarkIcon } from '../../../assets/checkMarkIcon.svg'
import BackButton from '../../../common/components/commonUI/BackButton'
import TitleWithSubtitle from '../../../common/components/commonUI/TitleWithSubtitle'
import { Organization, OrganizationDetail, OrganizationsApiFactory } from '../../../service/api'
import { ModalManager, ModalType } from './ModalManager'
import styles from './OrganizationsDetail.module.css'
import { OrganizationsForm } from './OrganizationsForm.tsx/OrganizationsForm'

interface OrganizationDetailParams {
    selectedOrganization: Organization
}
export const OrganizationsDetail = (): ReactElement => {
    const api = OrganizationsApiFactory()
    const [t] = useTranslation('translations')
    const location = useLocation()
    const [form] = Form.useForm()
    const [isModifying, setIsModifying] = useState<boolean>(false)
    const [modalType, setModalType] = useState<ModalType>(ModalType.NONE)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [operationComplete, setOperationComplete] = useState<boolean>(false)
    const [operationCompleteText, setOperationCompleteText] = useState<string>('')
    const navigate = useNavigate()
    const selectedOrganization: Organization = (location.state as OrganizationDetailParams).selectedOrganization
    const [currentOrganizationData, setCurrentOrganizationData] = useState<OrganizationDetail>()

    useEffect(() => {
        setLoading(true)
        api.getOrganizationDetails(selectedOrganization.pk).then(response => {
            setCurrentOrganizationData(response.data)
        }).catch((error: AxiosError) => {
            console.log(error.name)
        }).finally(() => {
            setLoading(false)
        })
    }, [])
    const submitForm = (values: OrganizationDetail) => {
        setLoading(true)
        values.pk = selectedOrganization.pk
        api.updateOrganization(values.pk, values).then(() => {
            setOperationComplete(true)
            setOperationCompleteText(t('organizationSaved'))
        }).catch(() => {
            setModalType(ModalType.ERROR_MODAL)
        }).finally(() => setLoading(false))
    }

    const handleDelete = () => {
        setLoading(true)
        api.deleteOrganization(selectedOrganization.pk).then((res) => {
            setOperationComplete(true)
            setOperationCompleteText(t('organizationDeleted'))
        }).catch(error => {
            handleOpenModal(ModalType.ERROR_MODAL)
        }).finally(() => setLoading(false))
    }

    useEffect(() => {
        setModalOpen(true)
    }, [modalType])

    const handleOpenModal = (type: ModalType) => {
        setModalOpen(true)
        setModalType(type)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }
    return (
        <div className={styles.container}>
            <div className={styles.titleRowContainer}>
                <TitleWithSubtitle title={selectedOrganization.name} />
                <div className={styles.buttonsContainer}>
                    {!isModifying ?
                        <>
                            <Button
                                disabled={loading}
                                onClick={() => handleOpenModal(ModalType.DELETE_MODAL)}
                                className={`blackFillPositiveButton ${styles.deleteButton}`}>
                                {t('delete')}
                            </Button>
                            <Button
                                disabled={loading}
                                onClick={() => setIsModifying(true)}
                                className={'yellowFillPositiveButton'}>
                                {t('edit')}
                            </Button>
                        </>
                        :
                        <>
                            <Button
                                disabled={loading}
                                onClick={() => handleOpenModal(ModalType.CANCEL_MODAL)}
                                className={`blackFillPositiveButton ${styles.deleteButton}`}>
                                {t('cancel')}
                            </Button>
                            <Button
                                loading={loading}
                                disabled={loading}
                                onClick={() => form.submit()}
                                className={'yellowFillPositiveButton'}>
                                {t('save')}
                            </Button>
                        </>
                    }
                </div>
            </div>
            <div className={styles.backContainer}>
                <BackButton />
            </div>
            <div className={styles.formContainer}>
                <OrganizationsForm
                    data={currentOrganizationData}
                    disabled={!isModifying}
                    onFinish={submitForm}
                    form={form} />
            </div>
            {modalOpen &&
                <ModalManager
                    onModalClose={handleCloseModal}
                    open={modalOpen}
                    type={modalType}
                    onCancelConfirm={() => {
                        handleCloseModal()
                        setIsModifying(false)
                    }}
                    onDeleteConfirm={handleDelete}
                />}

            <Modal
                closable={false}
                className={'modal'}
                open={operationComplete}
                footer={
                    <>
                        <Button
                            className={'blackFillPositiveButton'}
                            onClick={() => navigate(-1)}>
                            {'OK'}
                        </Button>
                    </>
                }>
                <CheckMarkIcon />
                <p>{operationCompleteText}</p>
            </Modal>
        </div>
    )
}
